export const otherTechnicalFactors = [
  { fieldKey: "narrowPath", label: "_narrowPath" },
  { fieldKey: "steepTransversalGradient", label: "_steepTransversalGradient" },
];

export const helpForHands = [
  { fieldKey: "handHelpAvailable", label: "_handHelpAvailable" },
];

export const pathEnvironment = [
  { fieldKey: "forestPath", label: "_forestPath" },
];

export const otherDangerFactors = [
  { fieldKey: "crest", label: "_crest" },
  { fieldKey: "upstreamObstacle", label: "_upstreamObstacle" },
  { fieldKey: "slopeTowardsFall", label: "_slopeTowardsFall" },
  { fieldKey: "unclearPathBorder", label: "_unclearPathBorder" },
  { fieldKey: "specialImpactSituation", label: "_specialImpactSituation" },
  { fieldKey: "probableImpact", label: "_probableImpact" },
];

export const equipment = [
  { fieldKey: "fenceSlowdown", label: "_fenceSlowdown" },
  { fieldKey: "chainCableRopeAvailable", label: "_chainCableRopeAvailable" },
];

export const otherDimensions = [
  { fieldKey: "scarySuspensionBridge", label: "_scarySuspensionBridge" },
  { fieldKey: "vertiginousView", label: "_vertiginousView" },
  { fieldKey: "seeThroughStairsBridge", label: "_seeThroughStairsBridge" },
  { fieldKey: "ladder", label: "_ladder" },
  { fieldKey: "icyPassages", label: "_icyPassages" },
  { fieldKey: "risingStream", label: "_risingStream" },
];
