const SWISSTOPO_GEOMETRY_TYPE = "esriGeometryPoint";
const SWISSTOPO_LAYERS = "all:ch.swisstopo.swisstlm3d-wanderwege";
const SWISSTOPO_MAP_EXTENT =
  "8.225000043, 46.815000098, 8.226323416, 46.815890570"; // equivalent of 0,0,100,100 in WGS84, based on central coords of Switzerland in LV95
const SWISSTOPO_IMAGE_DISPLAY = "100,100,100";
const SWISSTOPO_RETURN_GEOMETRY = true;
const SWISSTOPO_GEOMETRY_FORMAT = "geojson";
const SWISSTOPO_SPATIAL_REFERENCE_WGS84 = 4326; // WGS84
const SWISSTOPO_MAX_RESULTS = 200;

export const SWISSTOPO_TOLERANCE = 5000;

const endpoints = {
  identify: "identify",
};

export async function fetchSegments(lat, lng) {
  return fetchSegmentsRecursive(lat, lng, []);
}

async function fetchSegmentsRecursive(lat, lng, results, offset) {
  let params = {
    geometry: [lng, lat], // Careful, lat & lng are "switched" for the API call!
    geometryType: SWISSTOPO_GEOMETRY_TYPE,
    layers: SWISSTOPO_LAYERS,
    mapExtent: SWISSTOPO_MAP_EXTENT,
    imageDisplay: SWISSTOPO_IMAGE_DISPLAY,
    tolerance: SWISSTOPO_TOLERANCE,
    returnGeometry: SWISSTOPO_RETURN_GEOMETRY,
    geometryFormat: SWISSTOPO_GEOMETRY_FORMAT,
    sr: SWISSTOPO_SPATIAL_REFERENCE_WGS84,
  };

  if (offset) params.offset = offset;

  let urlParams = new URLSearchParams(params);

  let url = `${process.env.REACT_APP_SEGMENTS_BASEURL}/${endpoints.identify}?${urlParams}`;
  console.log('Test bug : '+ url);
  console.log('Test bug 2 : '+ process.env.REACT_APP_SEGMENTS_BASEURL);
  let result = await fetch(url);
  let response = await result.json();

  let segments = parseSegmentsResponse(response);

  results = [...results, ...segments];

  // There are more results to fetch
  if (segments.length > SWISSTOPO_MAX_RESULTS) {
    return fetchSegmentsRecursive(
      lat,
      lng,
      results,
      offset ? offset + SWISSTOPO_MAX_RESULTS + 1 : SWISSTOPO_MAX_RESULTS + 1
    );
  }

  return results;
}

function parseSegmentsResponse(response) {
  let results = response.results;

  return results.map((r) => ({
    id: r.id,
    coordinates: r.geometry.coordinates.map(([lng, lat]) => [lat, lng]), // Careful, lat & lng are "switched back" for Leaflet*/
  }));
}
