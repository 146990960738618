import { TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MissionContext } from "../context/MissionContext";

import { useDebounce } from "../hooks/useDebounce";

export default function SearchField() {
  const [t] = useTranslation("common");

  const { filterText, setFilterText } = useContext(MissionContext);

  const [filter, setFilter] = useState(filterText);

  const changeFilter = (e) => {
    setFilter(e.target.value);
  };

  const debouncedFilterText = useDebounce(filter, 500);

  // Effect for Context Update
  useEffect(
    () => {
      if (debouncedFilterText !== null && debouncedFilterText !== undefined) {
        setFilterText(debouncedFilterText);
      }
    },
    [debouncedFilterText, setFilterText] // Only call effect if debounced search term changes
  );

  return (
    <TextField
      value={filter}
      placeholder={`${t("_filterText")}...`}
      onChange={changeFilter}
      style={{ minWidth: 600 }}
    />
  );
}
