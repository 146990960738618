function descendingComparator(a, b, orderBy) {
  if (!a[orderBy]) return -1;

  if (!b[orderBy]) return 1;

  if (isNaN(a[orderBy])) {
    return b[orderBy].localeCompare(a[orderBy], undefined, {
      sensitivity: "base",
    });
  }

  if (+b[orderBy] < +a[orderBy]) {
    return -1;
  }
  if (+b[orderBy] > +a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
