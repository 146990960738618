import { Button, IconButton } from "@material-ui/core";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PodSelection({ missionID, pods, podID }) {
  const [t] = useTranslation("common");

  const history = useHistory();

  const sortedPods = pods.sort((p1, p2) => p1.date - p2.date);

  const currentPodIndex = podID
    ? sortedPods.findIndex((p) => p.id === podID)
    : -1;

  const currentPod = sortedPods[currentPodIndex];

  const handleClickNext = () => {
    selectPod(1);
  };

  const handleClickPrev = () => {
    selectPod(-1);
  };

  const selectPod = (direction) => {
    if (
      (currentPodIndex === 0 && direction < 0) ||
      (currentPodIndex === pods.length - 1 && direction > 0)
    )
      return;

    let newPodID = sortedPods[currentPodIndex + direction].id;
    history.push(`/validate-mission/${missionID}/${newPodID}`);
  };

  const editPod = () => {
    history.push(`/pod/${currentPod.id}`);
  };

  return (
    <div>
      <IconButton onClick={handleClickPrev}>
        <ArrowLeftIcon />
      </IconButton>
      {podID ? (
        <Button color="primary" onClick={editPod}>
          {t("_edit", { ns: "mobile" })} #{currentPod?.pod_id}
        </Button>
      ) : (
        "---"
      )}
      <IconButton onClick={handleClickNext}>
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
}
