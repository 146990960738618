import { StoreMallDirectory } from "@material-ui/icons";
import firebase from "firebase";
import "firebase/storage";

// FUNCTION TO DELETE THE POD IMAGE IN FIREBASE STORAGE
export async function deletePodImage(pod, doc_id) {
  const fileExtension = pod.img_download_url
    .toString()
    .substring(
      pod.img_download_url.indexOf("?") - 3,
      pod.img_download_url.indexOf("?")
    );

  const ref = await firebase
    .storage()
    .ref()
    .child("pods/" + doc_id + "." + fileExtension);

  ref.delete();
}

// FUNCTION TO DELETE THE POD IMAGE IN FIREBASE STORAGE FROM PATH
export async function deletePodImageFromPath(path) {
  var storage = firebase.storage();
  console.log(storage);
  var imgRef = storage.ref(path);
  console.log(imgRef);
  imgRef.delete();
}

// FUNCTION TO UPLOAD THE POD IMAGE IN FIREBASE STORAGE
export async function uploadPodImage(file, fileName, podId) {
  try {
    const storage = firebase.storage();

    const storageRef = storage.ref("pods");

    var sRef = storageRef.child(fileName);

    await sRef.put(file, {
      contentType: "image/jpeg",
    });
    console.log(sRef.getDownloadURL());
    return sRef.getDownloadURL();
  } catch (e) {
    if (e.message.includes("storage/file-not-found")) {
      console.error("Local file is gone!");
      return null; // If the local file disappeard for some reason, stop trying
    } else {
      throw e;
    }
  }
}
