import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_en from "./language/en/common.json";
import common_de from "./language/de/common.json";
import common_it from "./language/it/common.json";
import common_fr from "./language/fr/common.json";
import mobile_en from "./language/en/mobile.json";
import mobile_de from "./language/de/mobile.json";
import mobile_fr from "./language/fr/mobile.json";
import mobile_it from "./language/it/mobile.json";

import LanguageDetector from "i18next-browser-languagedetector";

//INIT THE TRANSLATION MODULE

i18next.use(LanguageDetector).init({
  //Using LanguageDetector so that the system language is detected at the start
  interpolation: { escapeValue: false }, // React already does escaping

  ns: ["common", "mobile"],
  defaultNS: "common",

  fallbackLng: "en",

  resources: {
    en: {
      common: common_en, // 'common' is the namespace for our translation file
      mobile: mobile_en,
    },
    de: {
      common: common_de,
      mobile: mobile_de,
    },
    it: {
      common: common_it,
      mobile: mobile_it,
    },
    fr: {
      common: common_fr,
      mobile: mobile_fr,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
