import { blueIcon, greenIcon, orangeIcon, redIcon } from "./icons";

export const USER_ROLES = {
  ADMINISTRATOR: "Administrator",
  EXPERT: "Expert",
  ADVANCED: "Advanced",
  BEGINNER: "Beginner",
  DELETED: "Deleted",
};

export const POD_STATUS = {
  VALIDATED: "validated",
  TO_VALIDATE: "toValidate",
  REJECTED: "rejected",
  TO_CHANGE: "toChange",
};

export const POD_COLOR_MAP = {
  [POD_STATUS.VALIDATED]: "green",
  [POD_STATUS.TO_VALIDATE]: "blue",
  [POD_STATUS.TO_CHANGE]: "orange",
  [POD_STATUS.REJECTED]: "red",
};

export const POD_MARKERS_MAP = {
  [POD_STATUS.VALIDATED]: greenIcon,
  [POD_STATUS.TO_VALIDATE]: blueIcon,
  [POD_STATUS.TO_CHANGE]: orangeIcon,
  [POD_STATUS.REJECTED]: redIcon,
};

export const MISSION_STATUS = {
  TO_DO: "todo",
  STARTED: "started",
  STOPPED: "stopped",
  TO_VALIDATE: "toValidate",
  VALIDATED: "validated",
  TRAINING: "training",
  EXAM: "exam",
};

export const FIRESTORE_COLLECTIONS = {
  PODS: "pointsOfDifficulty",
  USERS: "users",
  MISSIONS: "missions",
  SCORES: "scores",
};

export const DATE_FORMAT_MOMENT = "DD.MM.yyyy";
export const DATE_TIME_FORMAT_MOMENT = "DD.MM.yyyy HH:mm:ss";
export const DATE_TIME_FORMAT_MOMENT_MINUTES = "DD.MM.yyyy HH:mm";
export const DATE_FORMAT_DATEFNS = "dd.MM.yyyy";
export const DATE_TIME_FORMAT_MOMENT_EXPORT = "yyyy-MM-DD HH:mm:ss";
export const DATE_FORMAT_MOMENT_EXPORT = "yyyy-MM-DD";

export const MAP_TILE_URL =
  "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-grau/default/current/3857/{z}/{x}/{y}.jpeg";

export const MAP_TILE_URL_TRAILS =
  "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swisstlm3d-wanderwege/default/current/3857/{z}/{x}/{y}.png";
