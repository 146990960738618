import React, { useContext, useEffect, useRef, useState } from "react";
import { deletePod, getPod, updatePod } from "../database/firestore_requests";
import { mapDocToPod } from "../database/mapping_methods";
import Button from "@material-ui/core/Button";
import { ArrowBack } from "@material-ui/icons";

import {
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import firebase from "firebase";
import { useTranslation } from "react-i18next";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import {
  MAP_TILE_URL,
  MAP_TILE_URL_TRAILS,
  POD_MARKERS_MAP,
  POD_STATUS,
} from "../config/constants";
import { blackIcon } from "../config/icons";
import {
  dangerDimensions,
  getPodName,
  technicalDimensions,
} from "../dimensions/dimensionsSingleChoice";
import { UserContext } from "../context/UserContext";

import "./PodDetails.css";
import LevelButtons from "./LevelButtons";
import { Formik } from "formik";
import SingleChoiceDimension from "./dimensions/SingleChoiceDimension";
import MultipleChoiceDimension from "./dimensions/MultipleChoiceDimension";
import {
  equipment,
  helpForHands,
  otherDangerFactors,
  otherDimensions,
  otherTechnicalFactors,
  pathEnvironment,
} from "../dimensions/dimensionsMultipleChoice";

import _ from "lodash";
import { Alert } from "@material-ui/lab";
import Fancybox from "./Fancybox";
import DragDropFile from "./DragDropFile";
import { imageOverlay } from "leaflet";
import {
  uploadPodImage,
  deletePodImageFromPath,
} from "../database/storage_request";

//DISPLAY CLICKED POD DETAIL
export default function PodDetails({ match }) {
  const [t] = useTranslation();
  let { id } = match.params;
  const { users } = useContext(UserContext);
  let [pod, setPod] = useState(null);
  const markerRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  useEffect(() => {
    console.log("PodDetails");
    console.log("pod id " + id);
    const unsubscribe = getPod(
      {
        next: (documentSnapshot) => {
          let podFirestore = mapDocToPod(documentSnapshot);
          setPod(podFirestore);
        },

        error: (error) => {
          setPod(undefined);
        },
      },
      id
    );

    return () => unsubscribe();
  }, [id, setPod]);

  function ChangeMapView({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());

    return null;
  }

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const addImage = async (imgFile) => {
    var imgUrl = await uploadPodImage(imgFile[0], imgFile[0].name, pod.doc_id);
    pod.img_download_urls.push(imgUrl);
    const updatedPod = { ...pod };
    updatePod(updatedPod.doc_id, updatedPod);
  };

  const deleteImage = (imageUrl) => {
    const baseUrl =
      "https://firebasestorage.googleapis.com/v0/b/appcitizenscience.appspot.com/o/";
    var imagePath = imageUrl.replace(baseUrl, "");
    const indexOfEndPath = imagePath.indexOf("?");
    imagePath = imagePath.substring(0, indexOfEndPath);
    imagePath = imagePath.replace("%2F", "/");
    const updatedPod = { ...pod };
    var index = updatedPod.img_download_urls.indexOf(imageUrl);
    updatedPod.img_download_urls.splice(index, 1);
    updatePod(updatedPod.doc_id, updatedPod);
    deletePodImageFromPath(imagePath);
  };

  //Styles
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      display: "flex",
      overflow: "hidden",
      //backgroundColor: "red",
    },
    column: {
      display: "flex",
      overflowY: "auto",
    },
    columnLeft: {
      width: "30%",
    },
    columnRight: {
      width: "70%",
    },
    columnContent: {
      width: "100%",
    },
    padded: {
      margin: "0.5em",
    },
    paper: {
      padding: theme.spacing(1),
      margin: 15,
      textAlign: "center",
      color: theme.palette.text.primary,
      borderRadius: "25px",
      backgroundPosition: "left top",
      backgroundRepeat: "repeat",
    },
    formControl: {
      margin: theme.spacing(1),
      width: "50%",
      height: "100%",
    },
    formTitle: {
      marginTop: 10,
      marginBottom: 10,
      fontSize: 18,
    },
    selectEmpty: {
      marginTop: theme.spacing(1),
    },
    button: {
      marginLeft: 10,
      marginTop: 10,
    },
    photos: {
      display: "flex",
      flexDirection: "row",
      overflowX: "auto",
    },
    photo: {
      border: "1px solid lightgrey",
      display: "flex",
      margin: "0.5em",
      cursor: "pointer",
      alignItems: "center",
    },
    photoImage: {
      maxHeight: "200px",
      maxWidth: "200px",
    },
    toolbar: {
      textAlign: "center",
      padding: "0.5em",
    },
    cont: {
      flexDirection: "row",
      display: "flex",
      flexWrap: "wrap",
    },
    col50: {
      width: "50%",
    },
  }));

  let history = useHistory();
  const classes = useStyles();

  const handleSaveChanges = async (values, setValues) => {
    const { doc_id, ...data } = values;

    data.modification_date =
      new firebase.firestore.FieldValue.serverTimestamp();

    // save changed POD values
    await updatePod(doc_id, data);

    //open the snackbar
    setSnackbarOpen(true);
  };

  const handleDeletePod = async (docId) => {
    console.log("Delete POD : " + docId);
    deletePod(docId);

    //Redirection autre page
    history.goBack();
  };

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            startIcon={<ArrowBack />}
            onClick={() => history.goBack()}
          >
            {t("_back")}
          </Button>
        </div>
        {pod && users.length > 0 && (
          <Formik
            initialValues={pod}
            enableReinitialize={true}
            onSubmit={(values, { setValues }) =>
              handleSaveChanges(values, setValues)
            }
          >
            {({ values, setFieldValue, handleSubmit, isSubmitting }) => {
              const { latitude, longitude } = values.geo_position.toJSON();

              return (
                <>
                  <div className={classes.root}>
                    <div className={`${classes.column} ${classes.columnLeft}`}>
                      <div className={classes.columnContent}>
                        <div className={classes.padded}>
                          <Typography variant="body1" style={{ float: "left" }}>
                            {t("_podId")} : {values.pod_id}
                          </Typography>
                          <Typography
                            variant="body1"
                            style={{ float: "right", textAlign: "right" }}
                          >
                            {t("_owner")} :{" "}
                            {
                              users.find((u) => u.user_id === values.fk_user_id)
                                ?.username
                            }
                          </Typography>

                          <SingleChoiceDimension
                            fieldKey="name"
                            options={getPodName().sort((a, b) => {
                              return t(a.value, { ns: "mobile" }).localeCompare(
                                t(b.value, { ns: "mobile" })
                              );
                            })}
                            label={t("_podName")}
                            value={values.name}
                            setValue={(e) =>
                              setFieldValue("name", e.target.value)
                            }
                          />
                          <TextField
                            fullWidth
                            style={{ marginTop: 16 }}
                            label={t("_description", { ns: "mobile" })}
                            value={values.description}
                            onChange={(e) =>
                              setFieldValue(
                                "description",
                                e.target.value,
                                false
                              )
                            }
                          />
                          <Typography variant="h6">
                            {t("_coordinates", { ns: "mobile" })}{" "}
                            <small style={{ fontWeight: "normal" }}>
                              ({t("_clickAndDragToModify")})
                            </small>
                          </Typography>
                          <div className="leaflet-container">
                            <MapContainer
                              center={[latitude, longitude]}
                              zoom={17}
                              id="leafletMap"
                            >
                              <TileLayer url={MAP_TILE_URL} />
                              <TileLayer url={MAP_TILE_URL_TRAILS} />
                              <Marker
                                zIndex={10}
                                icon={POD_MARKERS_MAP[pod.status] ?? blackIcon}
                                draggable={true}
                                eventHandlers={{
                                  dragend: () => {
                                    const marker = markerRef.current;

                                    if (marker != null) {
                                      let { lat, lng } = marker.getLatLng();
                                      setFieldValue(
                                        "geo_position",
                                        new firebase.firestore.GeoPoint(
                                          lat,
                                          lng
                                        )
                                      );
                                    }
                                  },
                                }}
                                position={[latitude, longitude]}
                                ref={markerRef}
                              >
                                <Popup>
                                  <h1>{values.name}</h1>

                                  <p>
                                    {t("_moveTheMarker")}
                                    <EditLocationIcon color={"primary"} />
                                  </p>
                                </Popup>
                              </Marker>

                              <ChangeMapView coords={[latitude, longitude]} />
                            </MapContainer>
                          </div>
                          <Typography variant="h6">{t("_photos")}</Typography>
                          <div className={classes.photos}>
                            <Fancybox
                              options={{
                                infinite: false,
                              }}
                            >
                              {values.img_download_urls
                                .filter((img) => img)
                                .map((img) => (
                                  <div className={classes.photo} key={img}>
                                    <img
                                      data-fancybox="gallery"
                                      data-src={img}
                                      className={classes.photoImage}
                                      src={img}
                                      alt={values.name}
                                      draggable={true}
                                    />
                                  </div>
                                ))}
                            </Fancybox>
                          </div>
                          <div className={classes.cont}>
                            <div className={classes.col50}>
                              <DragDropFile
                                addOrDelete="add"
                                callbackFunction={addImage}
                              />
                            </div>
                            <div className={classes.col50}>
                              <DragDropFile
                                addOrDelete="delete"
                                callbackFunction={deleteImage}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`${classes.column} ${classes.columnRight}`}>
                      <div className={classes.columnContent}>
                        <div className={classes.padded}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                              gap: "2em",
                            }}
                          >
                            <LevelButtons
                              title={t("_technicalLevel")}
                              level={values.level_technical}
                              setLevel={(e) =>
                                setFieldValue(
                                  "level_technical",
                                  +e.target.value
                                )
                              }
                            />
                            <LevelButtons
                              title={t("_riskLevel")}
                              level={values.level_risk}
                              setLevel={(e) =>
                                setFieldValue("level_risk", +e.target.value)
                              }
                            />
                            <div style={{ flexGrow: 1 }}>
                              <InputLabel>{t("_status")}</InputLabel>
                              <Select
                                fullWidth
                                value={values.status}
                                onChange={(e) =>
                                  setFieldValue("status", e.target.value)
                                }
                              >
                                {Object.values(POD_STATUS).map((s) => (
                                  <MenuItem key={s} value={s}>
                                    {t(`_${s}`)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </div>
                          </div>
                          {/* Technical Dimensions */}
                          <Typography
                            variant="h5"
                            style={{ marginTop: "0.5em" }}
                          >
                            {t("_technicalDimensions", { ns: "mobile" })}
                          </Typography>
                          {technicalDimensions.map((dimension) => (
                            <SingleChoiceDimension
                              key={dimension.fieldKey}
                              fieldKey={dimension.fieldKey}
                              value={values[dimension.fieldKey]}
                              setValue={(e) =>
                                setFieldValue(
                                  dimension.fieldKey,
                                  e.target.value
                                )
                              }
                              label={t(dimension.placeholderKey, {
                                ns: "mobile",
                              })}
                              options={dimension.options}
                            />
                          ))}
                          {/* Other technical factors */}
                          <MultipleChoiceDimension
                            values={values}
                            title={t("_otherTechnicalFactors", {
                              ns: "mobile",
                            })}
                            options={otherTechnicalFactors}
                            onChange={setFieldValue}
                          />
                          {/* Help for hands */}
                          <MultipleChoiceDimension
                            values={values}
                            title={t("_helpForHands", { ns: "mobile" })}
                            options={helpForHands}
                            onChange={setFieldValue}
                          />
                          {/* Danger Dimensions */}
                          <Typography
                            variant="h5"
                            style={{ marginTop: "0.5em" }}
                          >
                            {t("_dangerDimensions", { ns: "mobile" })}
                          </Typography>
                          {dangerDimensions.map((dimension) => (
                            <SingleChoiceDimension
                              key={dimension.fieldKey}
                              fieldKey={dimension.fieldKey}
                              value={values[dimension.fieldKey]}
                              setValue={(e) =>
                                setFieldValue(
                                  dimension.fieldKey,
                                  e.target.value
                                )
                              }
                              label={t(dimension.placeholderKey, {
                                ns: "mobile",
                              })}
                              options={dimension.options}
                            />
                          ))}
                          {/* Path environment */}
                          <MultipleChoiceDimension
                            values={values}
                            title={t("_pathEnvironment", { ns: "mobile" })}
                            options={pathEnvironment}
                            onChange={setFieldValue}
                          />
                          {/* Other danger factors */}
                          <MultipleChoiceDimension
                            values={values}
                            title={t("_otherDangerFactors", { ns: "mobile" })}
                            options={otherDangerFactors}
                            onChange={setFieldValue}
                          />
                          {/* Equipment */}
                          <MultipleChoiceDimension
                            values={values}
                            title={t("_equipment", { ns: "mobile" })}
                            options={equipment}
                            onChange={setFieldValue}
                          />
                          {/* Other dimensions */}
                          <MultipleChoiceDimension
                            values={values}
                            title={t("_otherDimensions", { ns: "mobile" })}
                            options={otherDimensions}
                            onChange={setFieldValue}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.toolbar}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={_.isEqual(pod, values)}
                      onClick={handleSubmit}
                    >
                      {t("_saveModification")}
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeletePod(pod.doc_id)}
                    >
                      {t("_deletePod")}
                    </Button>
                  </div>
                </>
              );
            }}
          </Formik>
        )}
      </div>

      <Snackbar
        open={snackbarOpen}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Alert severity="success" onClose={handleClose}>
          {t("_podModified")}
        </Alert>
      </Snackbar>
    </>
  );
}
