import { Grid, makeStyles, Paper } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";

//Example contact page

export default function Contact() {
  const [t] = useTranslation("common");
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      margin: 15,
      textAlign: "center",
      color: theme.palette.text.primary,
      backgroundPosition: "left top",
      backgroundRepeat: "repeat",
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h1>{t("_contact")}</h1>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <p>
              {t("_inCaseOfQuestionsContact")}{" "}
              <a href="mailto:&#097;&#108;&#101;&#120;&#097;&#110;&#100;&#114;&#101;&#046;&#099;&#111;&#116;&#116;&#105;&#110;&#103;&#064;&#104;&#101;&#118;&#115;&#046;&#099;&#104;">
                &#097;&#108;&#101;&#120;&#097;&#110;&#100;&#114;&#101;&#046;&#099;&#111;&#116;&#116;&#105;&#110;&#103;&#064;&#104;&#101;&#118;&#115;&#046;&#099;&#104;
              </a>
            </p>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
