import { blue, green, orange, red } from "@material-ui/core/colors";
import {
  CheckCircle,
  CheckCircleOutline,
  ContactSupport,
  PanTool,
} from "@material-ui/icons";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonGroup } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import firebase from "../config/firebase";
import i18next from "i18next";
import { POD_STATUS } from "../config/constants";
import { Link } from "react-router-dom";

//This class regroups different methods to translate or to display different text / icons

//FUNCTION TO DISPLAY ICONS ACCORDING TO STATUS
export function StatusIconManagement(props) {
  const [t] = useTranslation("common");

  let statusComponent;

  let title = t(`_${props.status}`);

  switch (props.status) {
    case POD_STATUS.VALIDATED:
      statusComponent = (
        <CheckCircle style={{ color: green[500] }} titleAccess={title} />
      );
      break;
    case POD_STATUS.TO_VALIDATE:
      statusComponent = (
        <CheckCircleOutline
          style={{ color: orange[500] }}
          titleAccess={title}
        />
      );
      break;
    case POD_STATUS.REJECTED:
      statusComponent = (
        <ThumbDownIcon style={{ color: red[500] }} titleAccess={title} />
      );
      break;
    case POD_STATUS.TO_CHANGE:
      statusComponent = (
        <PanTool style={{ color: blue[500] }} titleAccess={title} />
      );
      break;
    default:
      statusComponent = <ContactSupport />;
  }

  return statusComponent;
}

//FUNCTION TO TRANSLATE LANGUAGE
export function LanguageManagement(props) {
  const [t] = useTranslation("common");
  let languageComponent;

  switch (props) {
    case "en":
      languageComponent = <>{t("_english")} </>;
      break;
    case "fr":
      languageComponent = <>{t("_french")}</>;
      break;
    case "de":
      languageComponent = <>{t("_german")}</>;
      break;
    case "it":
      languageComponent = <>{t("_italian")}</>;
      break;
    default:
      languageComponent = props;
  }

  return languageComponent;
}

//FUNCTION TO TRANSLATE STATUS
export function StatusManagement(props) {
  const [t] = useTranslation("common");
  let statusComponent;

  switch (props) {
    case POD_STATUS.VALIDATED:
      statusComponent = <>{t("_validated")} </>;
      break;
    case POD_STATUS.TO_VALIDATE:
      statusComponent = <>{t("_toValidate")}</>;
      break;
    case POD_STATUS.REJECTED:
      statusComponent = <>{t("_rejected")}</>;
      break;
    case POD_STATUS.TO_CHANGE:
      statusComponent = <>{t("_toChange")}</>;
      break;
    default:
      statusComponent = props;
  }

  return statusComponent;
}

//FUNCTION TO TRANSLATE DIFFERENT TITLES OF THE TABLE IN /user-management
export function UserTableTitle(props) {
  const [t] = useTranslation("common");
  let titleComponent;

  switch (props) {
    case "User ID":
      titleComponent = <>{t("_userid")}</>;
      break;
    case "User name":
      titleComponent = <>{t("_username")}</>;
      break;
    case "Role":
      titleComponent = <>{t("_role")}</>;
      break;
    case "Expert":
      titleComponent = <>{t("_expert")}</>;
      break;
    case "E-Mail":
      titleComponent = <>{t("_email")}</>;
      break;
    default:
      titleComponent = props;
  }

  return titleComponent;
}

//FUNCTION TO TRANSLATE AND DISPLAY THE TITLE IN H2
export function HeaderComponent() {
  const [t] = useTranslation("common");
  return (
    <div>
      <h2>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: "inherit",
          }}
        >
          {t("_title")}
        </Link>
      </h2>
    </div>
  );
}

//FUNCTION TO CHANGE LANGUAGE ON CLICK
export function ChangeLanguageHandler(props) {
  return i18next.changeLanguage(props);
}

//FUNCTION TO DISPLAY BUTTONS AND LOGOUT ON THE NAVBAR
export function LogoutComponent() {
  const [t] = useTranslation("common");

  return (
    <div>
      <ButtonGroup disableElevation variant="contained" color="primary">
        <Button onClick={() => ChangeLanguageHandler("de")}>Deutsch</Button>
        <Button onClick={() => ChangeLanguageHandler("en")}>English</Button>
        <Button onClick={() => ChangeLanguageHandler("fr")}>Français</Button>
        <Button onClick={() => ChangeLanguageHandler("it")}>Italiano</Button>
        <Button
          style={{ backgroundColor: "red" }}
          onClick={() => firebase.auth().signOut()}
        >
          {t("_logout")}
        </Button>
      </ButtonGroup>
    </div>
  );
}

//FUNCTION TO DISPLAY BUTTONS AT THE SIGN IN PAGE
export function SwitchLanguageComponent() {
  return (
    <div>
      <ButtonGroup color="primary" aria-label="outlined secondary button group">
        <Button onClick={() => ChangeLanguageHandler("de")}>Deutsch</Button>
        <Button onClick={() => ChangeLanguageHandler("en")}>English</Button>
        <Button onClick={() => ChangeLanguageHandler("fr")}>Français</Button>
        <Button onClick={() => ChangeLanguageHandler("it")}>Italiano</Button>
      </ButtonGroup>
    </div>
  );
}
