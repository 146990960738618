import React from "react";

//USER CONTEXT

export const UserContext = React.createContext({
  userAuthenticated: null,
  user: null,
  setUserAuthenticated: () => {},
  setUser: () => {},
  users: null,
  setUsers: () => {},
});
