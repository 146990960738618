import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  deletePod,
  missionsStream,
  userPodsStream,
} from "../database/firestore_requests";
import { mapDocToPod } from "../database/mapping_methods";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { StatusIconManagement } from "../utils/NameManagement";
import { getPodName } from "../dimensions/dimensionsSingleChoice";
import { Button } from "@material-ui/core";

//CALLED BY USERS POD TABLE, THE ROW IS FILLED WITH THE INFORMATIONS ABOUT POD ACCORDING TO THE USER

export default function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [t] = useTranslation("common");
  let [userPods, setUserPods] = useState([]);
  let [userMissions, setUserMissions] = useState([]);
  let userId = props.row.user_id;

  useEffect(() => {
    if (!userId) return;

    // Get a list of missions attributed to the user to add the mission name in the table
    const unsubscribe = missionsStream(
      {
        next: (querySnapshot) => {
          const missionsFirestore = querySnapshot.docs
            .filter((d) => d.data() !== undefined)
            .map((d) => ({
              mission_id: d.id,
              ...d.data(),
            }));

          missionsFirestore.sort((a, b) => {
            return a.due_date > b.due_date;
          });
          setUserMissions(missionsFirestore);
        },
        error: (error) => {
          setUserMissions([]);
        },
      },
      [["fk_user_id", "==", userId]]
    );
    return () => unsubscribe();
  }, [userId]);

  useEffect(() => {
    //Get a list of the pods created by the user connected from firestore and map every document to pod objects
    //Subscribe to any changes of the data and unsubscribe when not necessary anymore
    const unsubscribe = userPodsStream(
      {
        next: (querySnapshot) => {
          const userPodsFirestore = querySnapshot.docs
            .filter((d) => d.data().pod_id !== undefined)
            .map((d) => mapDocToPod(d));

          userPodsFirestore.sort((a, b) => {
            return a.date - b.date;
          });

          setUserPods(userPodsFirestore);
        },
        error: (error) => {
          setUserPods([]);
        },
      },
      userId
    );
    return () => unsubscribe();
  }, [setUserPods, userId]);

  return (
    <React.Fragment>
      <TableRow
        onClick={() => setOpen(!open)}
        style={{ backgroundColor: "#8ab6d6" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="medium"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="td" scope="row">
          {row.username}
        </TableCell>
        <TableCell>{row.email}</TableCell>
      </TableRow>

      <TableRow style={{ textAlign: "left" }}>
        <TableCell style={{ padding: 0, margin: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={0}>
              <Table size="medium">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#d8e3e7" }}>
                    <TableCell> {t("_podId")}</TableCell>
                    <TableCell>{t("_podName")}</TableCell>
                    <TableCell>{t("_description", { ns: "mobile" })}</TableCell>
                    <TableCell>{t("_missionName")}</TableCell>
                    <TableCell>{t("_status")}</TableCell>
                    <TableCell></TableCell>
                    <TableCell>{t("_deletePodsTitle")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {userPods.map((podsRow) => (
                    <TableRow
                      key={podsRow.doc_id}
                      hover
                      role="checkbox"
                      //onClick={() => handleNavigationPod(podsRow.doc_id)}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell>{podsRow.pod_id}</TableCell>
                      <TableCell>
                        {podsRow.name
                          ? t(
                              getPodName().find((o) => o.id === podsRow.name)
                                ?.value,
                              { ns: "mobile" }
                            )
                          : "---"}
                      </TableCell>

                      <TableCell>{podsRow.description || "---"}</TableCell>
                      <TableCell>
                        {userMissions.find(
                          (m) => podsRow.mission_id === m.mission_id
                        )?.name ?? "---"}
                      </TableCell>
                      <TableCell>{t("_" + podsRow.status)}</TableCell>

                      <TableCell>
                        <StatusIconManagement status={podsRow.status} />
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            console.log(podsRow.doc_id);

                            deletePod(podsRow.doc_id);
                          }}
                        >
                          {t("_deletePodsFonction")}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
