import { Adjust, Loop } from "@material-ui/icons";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  centerMarker: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10000,
    color: "blue",
    pointerEvents: "none",
  },
  rotateIcon: {
    animation: "$spin 4s linear infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
}));

export default function CenterMarker({ fetchingSegments }) {
  const classes = useStyles();

  return (
    <div className={classes.centerMarker}>
      {!fetchingSegments ? (
        <Adjust />
      ) : (
        <Loop fontSize="large" className={classes.rotateIcon} />
      )}
    </div>
  );
}
