import React, { useEffect } from "react";
import { useMapEvents } from "react-leaflet";
import { fetchSegments } from "../../services/segments";
import { makeStyles, Portal } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import MapSearch from "./MapSearch";
import { GeoAdminProvider } from "./GeoAdminProvider";

const useStyles = makeStyles((theme) => ({
  mapDetails: {
    width: "100%",
    marginTop: ".5em",
  },
  center: {
    textAlign: "center",
  },
  warning: {
    color: "red",
  },
}));

const MIN_ZOOM_FETCH = 12;

export default function MapDetails({
  containerRef,
  center,
  setCenter,
  zoom,
  setZoom,
  fetchingSegments,
  setFetchingSegments,
  segments,
  setSegments,
}) {
  const [t] = useTranslation("common");
  const classes = useStyles();

  const { lat, lng } = center;

  // Update zoom level & center coordinates
  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoom(mapEvents.getZoom());
    },
    dragend: () => {
      setCenter(mapEvents.getCenter());
      setSegments(null);
    },
    "geosearch/showlocation": (e) => {
      console.log("selected location", e);
      setCenter({ lat: e.location.y, lng: e.location.x });
      setSegments(null);
    },
  });

  // Fetch segments
  useEffect(() => {
    async function loadSegments() {
      setFetchingSegments(true);
      console.log("Fetching segments at", lat, lng);
      let segments = await fetchSegments(lat, lng);
      console.log(`Found ${segments.length} segments`);
      setSegments(segments);
      setFetchingSegments(false);
    }

    if (zoom >= MIN_ZOOM_FETCH && !fetchingSegments && segments === null) {
      loadSegments();
    } else if (fetchingSegments) {
      console.log("Already fetching! ");
    } else {
      console.log("Too far away");
    }
  }, [
    zoom,
    lat,
    lng,
    fetchingSegments,
    segments,
    setFetchingSegments,
    setSegments,
  ]);

  if (!containerRef.current) return null;

  const prov = new GeoAdminProvider();

  return (
    <>
      <MapSearch
        provider={prov}
        mapStyle="bar"
        showMarker={false}
        showPopup={false}
        keepResult={false}
        autoClose={true}
        autoCompleteDelay={500}
        searchLabel={t("_search")}
        retainZoomLevel={true}
      />
      <Portal container={containerRef.current}>
        <div className={classes.mapDetails}>
          {zoom < MIN_ZOOM_FETCH && segments === null ? (
            <p className={clsx(classes.center, classes.warning)}>
              {t("_increaseZoom")}
            </p>
          ) : (
            <p className={classes.center}>
              {t("_currentZoom")} : {zoom}, {t("_center")} : {lng.toFixed(4)},{" "}
              {lat.toFixed(4)}
            </p>
          )}
        </div>
      </Portal>
    </>
  );
}
