import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  FormControlLabel,
  FormControl,
  FormGroup,
  Link,
  List,
  ListItem,
  Radio,
  makeStyles,
} from "@material-ui/core";
import {
  addPod,
  changeMissionStatus,
  saveMission,
  updatePod,
} from "../database/firestore_requests";
import { UserContext } from "../context/UserContext";

import * as L from "leaflet";
import icons from "leaflet-color-number-markers";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  getMissionPods,
  getAuthenticatedUserId,
} from "../database/firestore_requests";
import {
  MapContainer,
  Marker,
  ScaleControl,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import Segment from "../components/map/Segment";
import CenterOnSegments from "../map/CenterOnSegments";
import {
  DATE_FORMAT_MOMENT,
  DATE_TIME_FORMAT_MOMENT,
  MAP_TILE_URL,
  MISSION_STATUS,
  POD_STATUS,
} from "../config/constants";

import Checkbox from "@material-ui/core/Checkbox";

import { getPodName } from "../dimensions/dimensionsSingleChoice";

import { MissionContext } from "../context/MissionContext";
import { renderToStaticMarkup } from "react-dom/server";
import moment from "moment";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { parseEvaluations } from "../utils/evaluations";
import { calculateCenter } from "../utils/coordinates";
import { CameraAlt, Room, List as ListIcon } from "@material-ui/icons";

import { blue, green } from "@material-ui/core/colors";

import "./MissionComparison.css";
import PhotoComparisonModal from "../components/PhotoComparisonModal";
import CriteriaComparisonModal from "../components/CriteriaComparisonModal";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mapContainer: {
    height: "500px",
    borderRadius: 0,
  },
}));

const COLOR_PALETTE = [
  "#e60049",
  "#0bb4ff",
  "#50e991",
  "#9b19f5",
  "#ffa300",
  "#00bfa0",
  "#e6d800",
  "#dc0ab4",
  "#b3d4ff",
];

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

const COMPARISON_MODES = {
  INDIVIDUAL: "individual",
  GROUPED: "grouped",
};

export default function MissionComparison() {
  const [t] = useTranslation("common");
  const history = useHistory();
  const classes = useStyles();
  const { users } = useContext(UserContext);
  const { missions } = useContext(MissionContext);

  const mapRef = useRef();

  const [groupedPODs, setGroupedPODs] = useState(null);

  const [missionPods, setMissionPods] = useState([]);

  const [selectedMissionIDs, setSelectedMissionIDs] = useState({});

  const [selectedMissionToClone, setSelectedMissionToClone] = useState({});

  const [center, setCenter] = useState(null);

  const [comparisonMode, setComparisonMode] = useState(
    COMPARISON_MODES.GROUPED
  );

  const [selectedPosition, setSelectedPosition] = useState(null);

  const [photosModalOpen, setPhotosModalOpen] = useState(false);

  const [criteriaModalOpen, setCriteriaModalOpen] = useState(false);

  const [cloneMission, setCloneMission] = useState(false);

  const query = useQuery();

  let missionIDs = useMemo(() => query.getAll("missionID"), [query]);

  let sampleMission = useMemo(() => {
    if (!missions || !missionIDs) return null;

    return missions.find((m) => m.mission_id === missionIDs[0]);
  }, [missionIDs, missions]);

  // Load grouped PODs from data
  useEffect(() => {
    async function parseGroupedPODs() {
      console.log("Looking at mission", sampleMission.name);

      let parsedPODs = await parseEvaluations(sampleMission.name);

      setGroupedPODs(parsedPODs);

      if (Object.keys(parsedPODs).length === 0)
        setComparisonMode(COMPARISON_MODES.INDIVIDUAL);
    }

    if (
      sampleMission &&
      comparisonMode === COMPARISON_MODES.GROUPED &&
      !groupedPODs
    )
      parseGroupedPODs();
  }, [sampleMission, groupedPODs, comparisonMode]);

  // Define all missions as checked by default
  useEffect(() => {
    const selectedMissions = {};

    for (let missionID of missionIDs) {
      selectedMissions[missionID] = true;
    }

    setSelectedMissionIDs(selectedMissions);
  }, [missionIDs]);

  // Fetch mission PODs
  useEffect(() => {
    async function fetchMissionPODs() {
      let allPods = [];
      for (let missionID of missionIDs) {
        let podDocs = await getMissionPods(missionID);
        allPods = [
          ...allPods,
          ...podDocs.docs.map((p) => ({ id: p.id, ...p.data() })),
        ];
      }
      setMissionPods(allPods);
    }

    if (missionIDs) fetchMissionPODs();
  }, [missionIDs]);

  // Formatted segments
  const formattedSegments = useMemo(() => {
    if (!sampleMission) return [];

    return sampleMission.segments.map((s) => ({
      ...s,
      coordinates: s.coordinates.map((c) => [c.latitude, c.longitude]),
    }));
  }, [sampleMission]);

  const getEffectiveStartDate = useCallback(
    (mission) => {
      let pods = missionPods
        .filter((p) => p.mission_id === mission.mission_id)
        .sort((p1, p2) => p1.date.toDate() - p2.date.toDate());
      return pods.length ? pods[0].date.toDate() : mission.start_date.toDate();
    },
    [missionPods]
  );

  const sortedMissions = useMemo(() => {
    if (!missions || !missionPods) return [];

    let missionsToCompare = missionIDs.map((mID) =>
      missions.find((m) => m.mission_id === mID)
    );

    missionsToCompare.sort(
      (m1, m2) => getEffectiveStartDate(m1) - getEffectiveStartDate(m2)
    );

    return missionsToCompare;
  }, [missions, missionIDs, missionPods, getEffectiveStartDate]);

  const missionUsers = useMemo(() => {
    if (!users.length || !sortedMissions.length) return {};

    let missionUsers = {};

    for (let mission of sortedMissions) {
      missionUsers[mission.fk_user_id] = {
        ...users.find((u) => u.user_id === mission.fk_user_id),
      };
    }

    return missionUsers;
  }, [users, sortedMissions]);

  const getMissionUserLabel = (mission) => {
    let missionUser = missionUsers[mission.fk_user_id];
    let pods = missionPods.filter(
      (p) =>
        p.mission_id === mission.mission_id && p.status === POD_STATUS.VALIDATED
    );

    return `${moment(getEffectiveStartDate(mission)).format(
      DATE_FORMAT_MOMENT
    )} - ${missionUser.username} (${pods.length} ${t("_pod_plural")})`;
  };

  const toggleMission = (missionID) => {
    let updatedMissionIDs = { ...selectedMissionIDs };

    updatedMissionIDs[missionID] = !updatedMissionIDs[missionID];

    setSelectedMissionIDs(updatedMissionIDs);
  };

  const toggleMissionToClone = (mission) => {
    setSelectedMissionToClone(mission);
  };

  const handleComparisonModeChange = (e, v) => {
    if (v) setComparisonMode(v);
    setTimeout(() => mapRef.current.invalidateSize(), 500);
  };

  const handlePositionClick = (n) => {
    setSelectedPosition(n);
    console.log(groupedPODs[n]);

    mapRef.current.fitBounds(
      [calculateCenter(groupedPODs[n].map((p) => [p.latitude, p.longitude]))],
      { maxZoom: 17, padding: [10, 10] }
    );
  };

  const togglePhotosModal = () => {
    setPhotosModalOpen((o) => !o);
    setCriteriaModalOpen(false);
  };

  const toggleCriteriaModal = () => {
    setCriteriaModalOpen((o) => !o);
    setPhotosModalOpen(false);
  };

  const handleSelecteToCloneClick = async () => {
    setCloneMission(!cloneMission);
  };

  const saveNewMission = async (mission) => {
    try {
      let missionID = await saveMission(
        mission.ref,
        mission.missionName,
        mission.fk_user_id,
        mission.due_date,
        mission.segments,
        mission.creatorUID,
        mission.missionID
      );
      return missionID;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloneClick = async () => {
    //Get PODs from the selected user only
    let userPods = missionPods.filter((pod) => {
      return pod.fk_user_id === selectedMissionToClone.fk_user_id;
    });

    let selectedSegments = selectedMissionToClone.segments.map((s) => ({
      ...s,
      coordinates: s.coordinates.map((c) => [c.latitude, c.longitude]),
    }));

    let currentUserId = await getAuthenticatedUserId();

    //Copy selected mission data
    let missionToSave = {
      ref: true,
      missionName: selectedMissionToClone.name + " référence",
      fk_user_id: currentUserId,
      due_date: selectedMissionToClone.due_date,
      segments: selectedSegments,
      creatorUID: currentUserId,
      missionID: null,
    };

    //Save new mission and get new mission ID
    var newMissionID = await saveNewMission(missionToSave);
    changeMissionStatus(newMissionID, MISSION_STATUS.VALIDATED);

    for (let userPod of userPods) {
      let pod = { ...userPod, mission_id: newMissionID, id: null };
      let id = await addPod(pod);
      pod.id = id;
      await updatePod(id, pod);
    }

    //Navigate to validate-mission screen
    history.push(`/validate-mission/${newMissionID}`);
  };

  if (!missions || !users || !sampleMission || !missionUsers)
    return <p>{t("_loading")}...</p>;

  return (
    <div
      className={classes.root}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h2>
        {t("_compareMissions")} - {sampleMission.name}
      </h2>
      <div
        className="container-overall"
        style={{ display: "flex", flexGrow: 1 }}
      >
        <MapContainer
          id="leafletMap"
          className={classes.mapContainer}
          whenCreated={(mapInstance) => {
            mapRef.current = mapInstance;
          }}
          style={{ flex: 3, height: "100%" }}
        >
          <TileLayer url={MAP_TILE_URL} maxNativeZoom={19} maxZoom={25} />
          <ScaleControl position="topright" imperial={false} maxWidth={200} />
          {formattedSegments.map((s) => (
            <Segment
              key={s.id}
              id={s.id}
              selected={true}
              coordinates={s.coordinates}
              editing={false}
            />
          ))}
          {comparisonMode === COMPARISON_MODES.INDIVIDUAL &&
            missionPods
              .filter((p) => selectedMissionIDs[p.mission_id])
              .map((p) => (
                <Marker
                  key={p.id}
                  position={[p.geo_position.latitude, p.geo_position.longitude]}
                  eventHandlers={{
                    click: () => {
                      history.push({
                        pathname: `/pod/${p.id}`,
                      });
                    },
                  }}
                  icon={L.divIcon({
                    html: renderToStaticMarkup(
                      <Room
                        style={{
                          fontSize: 40,
                          color:
                            COLOR_PALETTE[
                              sortedMissions.findIndex(
                                (m) => p.mission_id === m.mission_id
                              ) % COLOR_PALETTE.length
                            ],
                        }}
                      />
                    ),
                    iconAnchor: [20, 40],
                    className: "myDivIcon",
                  })}
                >
                  <Tooltip>
                    {
                      <div>
                        {p.name && (
                          <>
                            <span>
                              <strong>
                                {t(
                                  getPodName().find((o) => o.id === p.name)
                                    ?.value,
                                  {
                                    ns: "mobile",
                                  }
                                )}
                              </strong>
                            </span>
                            <br />
                          </>
                        )}
                        {p.description && (
                          <>
                            <span>{p.description}</span>
                            <br />
                          </>
                        )}
                        <span>
                          {t("_status")} : {t(`_${p.status}`)}
                        </span>
                        <br />
                        <span>
                          {missionUsers && missionUsers[p.fk_user_id].username}
                        </span>
                        <br />
                        <span>
                          {moment(p.date.toDate()).format(
                            DATE_TIME_FORMAT_MOMENT
                          )}
                        </span>
                      </div>
                    }
                  </Tooltip>
                </Marker>
              ))}
          {comparisonMode === COMPARISON_MODES.GROUPED &&
            groupedPODs &&
            Object.entries(groupedPODs).map(([n, pods]) => (
              <Marker
                key={n}
                position={calculateCenter(
                  pods.map((p) => [p.latitude, p.longitude])
                )}
                icon={
                  selectedPosition === +n
                    ? icons.red.numbers[n]
                    : icons.blue.numbers[n]
                }
                eventHandlers={{
                  click: () => {
                    setSelectedPosition(+n);
                  },
                }}
              />
            ))}
          {/* Component to fit bounds of the map */}
          <CenterOnSegments
            segments={formattedSegments}
            setCenter={setCenter}
          />
        </MapContainer>
        <div style={{ flex: 1, margin: "0 1em" }}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1em",
              }}
            >
              {groupedPODs && Object.keys(groupedPODs).length > 0 && (
                <ToggleButtonGroup
                  value={comparisonMode}
                  onChange={handleComparisonModeChange}
                  exclusive
                  aria-label="text formatting"
                >
                  <ToggleButton
                    value={COMPARISON_MODES.GROUPED}
                    aria-label="italic"
                  >
                    {t("_compareByPosition")}
                  </ToggleButton>
                  <ToggleButton
                    value={COMPARISON_MODES.INDIVIDUAL}
                    aria-label="bold"
                  >
                    {t("_compareAllMissions")}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            </div>
            {comparisonMode === COMPARISON_MODES.INDIVIDUAL && (
              <div>
                <strong>{t("_selectMissions")}</strong>
                {Object.keys(missionUsers).length > 0 &&
                  sortedMissions &&
                  missionPods.length > 0 &&
                  sortedMissions.map((mission, index) => {
                    return (
                      <FormGroup key={mission.mission_id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={selectedMissionIDs[mission.mission_id]}
                              onChange={() => toggleMission(mission.mission_id)}
                              color="primary"
                            />
                          }
                          label={getMissionUserLabel(mission)}
                          style={{
                            color: COLOR_PALETTE[index % COLOR_PALETTE.length],
                          }}
                        />
                      </FormGroup>
                    );
                  })}
              </div>
            )}
            {comparisonMode === COMPARISON_MODES.GROUPED && (
              <div>
                <strong>{t("_selectPosition")}</strong>
                <List style={{ overflowY: "auto", maxHeight: "200px" }}>
                  {groupedPODs &&
                    Object.keys(groupedPODs).map((n) => (
                      <ListItem key={n}>
                        <Link
                          component="button"
                          variant="body2"
                          color={
                            selectedPosition === +n ? "secondary" : "primary"
                          }
                          onClick={() => {
                            handlePositionClick(+n);
                          }}
                        >
                          {t("_position")} #{n}
                        </Link>
                      </ListItem>
                    ))}
                </List>
                {selectedPosition && (
                  <div style={{ margin: "1em", textAlign: "center" }}>
                    <Button
                      startIcon={<CameraAlt />}
                      variant="contained"
                      color="primary"
                      onClick={togglePhotosModal}
                      style={{ width: "100%", marginBottom: "1em" }}
                    >
                      {t("_comparePhotos")}
                    </Button>
                    {groupedPODs && Object.keys(groupedPODs).length > 0 && (
                      <PhotoComparisonModal
                        photosModalOpen={photosModalOpen}
                        togglePhotosModal={togglePhotosModal}
                        toggleCriteriaModalOpen={toggleCriteriaModal}
                        groupedPODs={groupedPODs}
                        selectedPosition={selectedPosition}
                        missionPods={missionPods}
                        users={users}
                      />
                    )}
                    <Button
                      startIcon={<ListIcon />}
                      variant="contained"
                      color="primary"
                      onClick={toggleCriteriaModal}
                      style={{ width: "100%", marginBottom: "1em" }}
                    >
                      {t("_compareCriteria")}
                    </Button>
                    {groupedPODs && Object.keys(groupedPODs).length > 0 && (
                      <CriteriaComparisonModal
                        criteriaModalOpen={criteriaModalOpen}
                        toggleCriteriaModalOpen={toggleCriteriaModal}
                        togglePhotosModalOpen={togglePhotosModal}
                        groupedPODs={groupedPODs}
                        selectedPosition={selectedPosition}
                        missionPods={missionPods}
                        users={users}
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <Button
            variant="contained"
            onClick={handleSelecteToCloneClick}
            //startIcon={<CheckCircleOutline />}
            style={{ color: "white", backgroundColor: blue[500] }}
          >
            {t("_selectMissionToClone")}
          </Button>
          {cloneMission && (
            <div>
              <strong>{t("_selectMissionToClone")}</strong>
              {Object.keys(missionUsers).length > 0 &&
                sortedMissions &&
                missionPods.length > 0 &&
                sortedMissions.map((mission, index) => {
                  return (
                    <FormControl key={mission.mission_id}>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={
                              selectedMissionToClone.mission_id ===
                              mission.mission_id
                            }
                            onChange={() => toggleMissionToClone(mission)}
                            color="primary"
                          />
                        }
                        label={getMissionUserLabel(mission)}
                        style={{
                          color: COLOR_PALETTE[index % COLOR_PALETTE.length],
                        }}
                      />
                    </FormControl>
                  );
                })}
            </div>
          )}
          {cloneMission && !!selectedMissionToClone.mission_id && (
            <Button
              variant="contained"
              onClick={handleCloneClick}
              startIcon={<FileCopyIcon />}
              style={{ color: "white", backgroundColor: green[500] }}
            >
              {t("_cloneMission")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
