import {Radio, withStyles} from "@material-ui/core";
import {blue, green, grey, orange, red} from "@material-ui/core/colors";
import React from "react";


//This class defines the different styles and colors for the radio button for Risk and Technical levels radio group


//Level 1 color
export const GreenRadio = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);



//Level 2 color
export const BlueRadio = withStyles({
    root: {
        color: blue[400],
        '&$checked': {
            color: blue[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);


//Level 3 color
export const OrangeRadio = withStyles({
    root: {
        color: orange[400],
        '&$checked': {
            color: orange[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

//Level 4 color
export const RedRadio = withStyles({
    root: {
        color: red[400],
        '&$checked': {
            color: red[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

//Level 5 color
export const GreyRadio = withStyles({
    root: {
        color: "darkgrey",
        '&$checked': {
            color: grey[600],
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);
