import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { USER_ROLES } from "../config/constants";
import SyncIcon from "@material-ui/icons/Sync";

//Example contact page

export default function Home() {
  const [t] = useTranslation("common");
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      margin: 15,
      textAlign: "center",
      color: theme.palette.text.primary,
      backgroundPosition: "left top",
      backgroundRepeat: "repeat",
      flex: 1,
    },
    rotateIcon: {
      animation: "spin 3s linear infinite",
      animationDirection: "reverse",
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  }));
  const classes = useStyles();
  const { user } = useContext(UserContext);

  if (!user)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <SyncIcon className={classes.rotateIcon} fontSize="large" />
      </div>
    );

  return (
    <div className={classes.root}>
      <div style={{ maxWidth: "100%" }}>
        <Grid container spacing={0} alignItems="stretch">
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <h1>{t("_title")}</h1>
            </Paper>
          </Grid>
          <Grid item style={{ flexGrow: 1, display: "flex" }}>
            <Paper className={classes.paper}>
              <div>
                <Typography variant="h5">
                  {t("_mission", { count: 2 })}
                </Typography>
                <p>
                  <Link to="/create-mission">{t("_createMission")}</Link>
                </p>
                <p>
                  <Link to="/missions">{t("_missionValidation")}</Link>
                </p>
                <p>
                  <Link to="/training-exam">{t("_reviewTrainingExam")}</Link>
                </p>
              </div>
            </Paper>
          </Grid>
          <Grid item style={{ flexGrow: 1, display: "flex" }}>
            <Paper className={classes.paper}>
              <div>
                <Typography variant="h5">{t("_pod", { count: 2 })}</Typography>
                <p>
                  <Link to="/pod-validation">{t("_podValidation")}</Link>
                </p>
              </div>
            </Paper>
          </Grid>
          {user?.role === USER_ROLES.ADMINISTRATOR && (
            <Grid item style={{ flexGrow: 1, display: "flex" }}>
              <Paper className={classes.paper}>
                <div>
                  <Typography variant="h5">
                    {t("_administrator", { count: 2 })}
                  </Typography>
                  <p>
                    <Link to="/user-management">{t("_userManagement")}</Link>
                  </p>
                  <p>
                    <Link to="/pod-management">{t("_podManagement")}</Link>
                  </p>
                  <p>
                    <Link to="/compare-missions">{t("_compareMissions")}</Link>
                  </p>
                </div>
              </Paper>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}
