import React, { useContext } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import PodRow from "./PodRow";
import { UserContext } from "../context/UserContext";

//TABLE TO DISPLAY ALL PODS ACCORDING TO THE ATTRIBUTED USERS OF THE EXPERT,
// CALLING PodRow.js as Row inside the users

export default function PodValidationTable() {
  const [t] = useTranslation("common");

  const { users } = useContext(UserContext);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead style={{ backgroundColor: " #5390d9 " }}>
          <TableRow>
            <TableCell />
            <TableCell>{t("_beginner")}</TableCell>
            <TableCell>{t("_email")}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {
            users.map((user) => (
            <PodRow key={user.user_id} row={user} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
