import * as React from "react";
import PodFinduser from "../components/PodFindUser";
import { Button } from "@material-ui/core";
import { getMissions, getPods, getUsers } from "../database/firestore_requests";
import { useState } from "react";
import { formatPODs } from "../export/data-export";
import { useTranslation } from "react-i18next";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import {
  getDownstreamSlopeGradient,
  getDownstreamSlopeLength,
  getInstability,
  getIrregularity,
  getLongitudinalGradient,
  getObstacleHeight,
  getPathWidth,
  getPodName,
  getSlipperiness,
} from "../dimensions/dimensionsSingleChoice";

export default function PodManagement() {
  const [t] = useTranslation("common");

  const [isExporting, setIsExporting] = useState(false);

  const exportPODs = async () => {
    setIsExporting(true);
    let missions = await getMissions();
    let pods = await getPods();
    let users = await getUsers();

    missions = missions.docs.map((m) => ({ id: m.id, ...m.data() }));
    pods = pods.docs
      .map((p) => ({ id: p.id, ...p.data() }))
      .sort(
        (p1, p2) => p1.date.toDate().getTime() - p2.date.toDate().getTime()
      );
    users = users.docs.map((u) => ({ id: u.id, ...u.data() }));

    console.log("# Missions", missions.length);
    console.log("# PODs", pods.length);
    console.log("# Users", users.length);

    let [header, formattedPODs] = formatPODs(
      missions,
      pods,
      users,
      t,
      false,
      false
    );

    let csvString = Papa.unparse({ fields: header, data: formattedPODs });
    let blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    saveAs(blob, "pods-export.csv");

    setIsExporting(false);
  };

  const exportMappings = () => {
    const header = ["field_name", "field_value", "field_label"];
    const records = [header];

    pushOptions("name", getPodName(), records);
    pushOptions("irregularity", getIrregularity(), records);
    pushOptions("instability", getInstability(), records);
    pushOptions("slipperiness", getSlipperiness(), records);
    pushOptions("obstacleHeight", getObstacleHeight(), records);
    pushOptions("longitudinalGradient", getLongitudinalGradient(), records);
    pushOptions("pathWidth", getPathWidth(), records);
    pushOptions(
      "longitudinalGradientDanger",
      getLongitudinalGradient(),
      records
    );
    pushOptions(
      "downstreamSlopeGradient",
      getDownstreamSlopeGradient(),
      records
    );
    pushOptions("downstreamSlopeLength", getDownstreamSlopeLength(), records);

    let csvString = Papa.unparse(records);
    let blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    saveAs(blob, "pods-mappings.csv");
  };

  function pushOptions(fieldName, options, records) {
    for (let option of options) {
      records.push([fieldName, option.id, t(option.value, { ns: "mobile" })]);
    }
  }

  return (
    <div>
      <PodFinduser />
      <Button
        variant="contained"
        color="primary"
        onClick={exportPODs}
        disabled={isExporting}
      >
        {!isExporting ? "Export PODs" : "Exporting PODs..."}
      </Button>{" "}
      <Button variant="contained" color="primary" onClick={exportMappings}>
        Export Mappings
      </Button>
    </div>
  );
}
