import React from "react";

//USER CONTEXT

export const MissionContext = React.createContext({
  missions: null,
  setMissions: () => {},
  referenceMissions: null,
  setReferenceMissions: () => {},
  hideValidated: false,
  setHideValidated: () => {},
  order: null,
  setOrder: () => {},
  orderBy: null,
  setOrderBy: () => {},
  orderComparison: null,
  setOrderComparison: () => {},
  orderByComparison: null,
  setOrderComparisonBy: () => {},
  filterText: null,
  setFilterText: () => {},
});
