import { Polyline, Circle, useMap } from "react-leaflet";
import React, { useEffect, useState } from "react";
import { SEGMENT_COLORS } from "../../config/map";

import "./Segment.css";

export default function Segment({
  id,
  coordinates,
  selected,
  unavailable,
  toggleSegment,
  editing = true,
}) {
  const [hovering, setHovering] = useState(false);

  const getSegmentColor = () => {
    if (selected) return SEGMENT_COLORS.selected;

    if (unavailable) return SEGMENT_COLORS.unavailable;

    if (hovering) return SEGMENT_COLORS.hover;

    return SEGMENT_COLORS.default;
  };

  const startMarker = coordinates[0];

  return (
    <>
      <Polyline
        pathOptions={{
          color: getSegmentColor(),
          weight: 7,
        }}
        className={unavailable ? "not-allowed" : null}
        positions={coordinates}
        eventHandlers={
          editing && !unavailable
            ? {
                mouseover: () => {
                  setHovering(true);
                },
                mouseout: () => {
                  setHovering(false);
                },
                click: () => {
                  toggleSegment({ id, coordinates });
                },
              }
            : null
        }
      />
      <Circle
        center={startMarker}
        radius={20}
        pathOptions={{
          color: "black", // Border color
          fillColor: "lightblue",
          fillOpacity: 1, // Solid fill
          weight: 2, // Border weight
        }}
      />
    </>
  );
}
