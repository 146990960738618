import * as React from "react";
import UsersTable from "../components/UsersTable";

//This screen calls the User table

export default function UserManagement() {
  return (
    <div>
      <UsersTable />
    </div>
  );
}
