// Technical dimensions
export function getIrregularity() {
  return [
    {
      value: "_none",
      id: 100,
      position: 1000,
    },
    {
      value: "_irregularityRegular",
      id: 101,
      position: 1,
    },
    {
      value: "_irregularityFairlyRegular",
      id: 102,
      position: 2,
    },
    {
      value: "_irregularityIrregular",
      id: 103,
      position: 3,
    },
    {
      value: "_irregularityVeryIrregular",
      id: 104,
      position: 4,
    },
  ];
}

export function getInstability() {
  return [
    {
      value: "_none",
      id: 5,
      position: 1000,
    },
    {
      value: "_instabilityStable",
      id: 6,
      position: 1,
    },
    {
      value: "_instabilityFairlyStable",
      id: 7,
      position: 2,
    },
    {
      value: "_instabilityUnstable",
      id: 8,
      position: 3,
    },
    {
      value: "_instabilityVeryUnstable",
      id: 9,
      position: 4,
    },
  ];
}

export function getSlipperiness() {
  return [
    {
      value: "_none",
      id: 10,
      position: 1000,
    },
    {
      value: "_slipperinessNotSlippery",
      id: 11,
      position: 1,
    },
    {
      value: "_slipperinessFairlySlippery",
      id: 12,
      position: 2,
    },
    {
      value: "_slipperinessSlippery",
      id: 13,
      position: 3,
    },
    {
      value: "_slipperinessVerySlippery",
      id: 14,
      position: 4,
    },
  ];
}

export function getObstacleHeight() {
  return [
    {
      value: "_none",
      id: 15,
      position: 1000,
    },
    {
      value: "_obstacleHeightLessThan10",
      id: 16,
      position: 1,
    },
    {
      value: "_obstacleHeight10To20",
      id: 17,
      position: 2,
    },
    {
      value: "_obstacleHeight21To50",
      id: 18,
      position: 3,
    },
    {
      value: "_obstacleHeightMoreThan50",
      id: 19,
      position: 4,
    },
    {
      value: "_obstacleHeightClimbing",
      id: 20,
      position: 5,
    },
  ];
}

export function getLongitudinalGradient() {
  return [
    {
      value: "_none",
      id: 21,
      position: 1000,
    },
    {
      value: "_longitudinalGradientLessThan10Or18",
      id: 22,
      position: 1,
    },
    {
      value: "_longitudinalGradient11To20Or18To36",
      id: 23,
      position: 2,
    },
    {
      value: "_longitudinalGradient21To30Or37To58",
      id: 24,
      position: 3,
    },
    {
      value: "_longitudinalGradient31To40Or59To83",
      id: 25,
      position: 4,
    },
    {
      value: "_longitudinalGradientMoreThan40Or83",
      id: 26,
      position: 5,
    },
  ];
}

// Danger dimensions
export function getPathWidth() {
  return [
    {
      value: "_none",
      id: 27,
      position: 1000,
    },
    {
      value: "_pathWidthMoreThan200",
      id: 28,
      position: 1,
    },
    {
      value: "_pathWidth121To200",
      id: 29,
      position: 2,
    },
    {
      value: "_pathWidth81To120",
      id: 30,
      position: 3,
    },
    {
      value: "_pathWidth31To80",
      id: 31,
      position: 4,
    },
    {
      value: "_pathWidthLessThan30",
      id: 32,
      position: 5,
    },
  ];
}

export function getDownstreamSlopeGradient() {
  return [
    {
      value: "_none",
      id: 33,
      position: 1000,
    },
    {
      value: "_downstreamSlopeGradientNone",
      id: 34,
      position: 1,
    },
    {
      value: "_downstreamSlopeGradientNoHands",
      id: 35,
      position: 2,
    },
    {
      value: "_downstreamSlopeGradientWithHands",
      id: 36,
      position: 3,
    },
    {
      value: "_downstreamSlopeGradientHelpOnly",
      id: 37,
      position: 4,
    },
    {
      value: "_downstreamSlopeGradientVertical",
      id: 38,
      position: 5,
    },
  ];
}

export function getDownstreamSlopeLength() {
  return [
    {
      value: "_none",
      id: 39,
      position: 1000,
    },
    {
      value: "_downstreamSlopeLengthLessThan5",
      id: 40,
      position: 1,
    },
    {
      value: "_downstreamSlopeLength6To10",
      id: 41,
      position: 2,
    },
    {
      value: "_downstreamSlopeLength11To20",
      id: 42,
      position: 3,
    },
    {
      value: "_downstreamSlopeLengthMoreThan20",
      id: 43,
      position: 4,
    },
    {
      value: "_downstreamSlopeLengthVerticalTalon",
      id: 44,
      position: 5,
    },
    {
      value: "_downstreamSlopeLengthVerticalCliff",
      id: 45,
      position: 6,
    },
  ];
}

// POD Name
export function getPodName() {
  return [
    {
      value: "_podNameAutre",
      id: 46,
      position: 1,
    },
    {
      value: "_podNamePath",
      id: 47,
      position: 6,
    },
    {
      value: "_podNameStream",
      id: 48,
      position: 25,
    },
    {
      value: "_podNameCrest",
      id: 49,
      position: 2,
    },
    {
      value: "_podNameLadder",
      id: 50,
      position: 36,
    },
    {
      value: "_podNameStairs",
      id: 51,
      position: 10,
    },
    {
      value: "_podNameObstacle",
      id: 52,
      position: 37,
    },
    {
      value: "_podNameObstacleTemp",
      id: 53,
      position: 38,
    },
    {
      value: "_podNameSlope",
      id: 54,
      position: 16,
    },

    {
      value: "_podNameStones",
      id: 55,
      position: 4,
    },

    {
      value: "_podNameBridge",
      id: 56,
      position: 20,
    },
    {
      value: "_podNameRoots",
      id: 57,
      position: 21,
    },
  ];
}

export const technicalDimensions = [
  {
    fieldKey: "irregularity",
    placeholderKey: "_irregularity",
    options: getIrregularity(),
  },
  {
    fieldKey: "instability",
    placeholderKey: "_instability",
    options: getInstability(),
  },
  {
    fieldKey: "slipperiness",
    placeholderKey: "_slipperiness",
    options: getSlipperiness(),
  },
  {
    fieldKey: "obstacleHeight",
    placeholderKey: "_obstacleHeight",
    options: getObstacleHeight(),
  },
  {
    fieldKey: "longitudinalGradient",
    placeholderKey: "_longitudinalGradient",
    options: getLongitudinalGradient(),
  },
];

export const dangerDimensions = [
  {
    fieldKey: "pathWidth",
    placeholderKey: "_pathWidth",
    options: getPathWidth(),
  },
  {
    fieldKey: "longitudinalGradientDanger",
    placeholderKey: "_longitudinalGradient",
    options: getLongitudinalGradient(),
  },
  {
    fieldKey: "downstreamSlopeGradient",
    placeholderKey: "_downstreamSlopeGradient",
    options: getDownstreamSlopeGradient(),
  },
  {
    fieldKey: "downstreamSlopeLength",
    placeholderKey: "_downstreamSlopeLength",
    options: getDownstreamSlopeLength(),
  },
];
