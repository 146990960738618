import { useTranslation } from "react-i18next";
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mapContainer: {
    height: "500px",
    borderRadius: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rotateIcon: {
    animation: "spin 3s linear infinite",
    animationDirection: "reverse",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
}));

export default function FiltreImages() {
  const [t] = useTranslation("common");

  const classes = useStyles();

  return (
    <div
      className={classes.root}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h2>{t("_FiltreImage")}</h2>
      <br />
    </div>
  );
}
