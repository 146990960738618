import React, { useContext, useEffect, useState } from "react";
import {
  AddExpert,
  Blacklist,
  Deletelist,
  deletePod,
  getUser,
  getUserPods,
  ModifyAttributed,
  RemoveExpert,
  updateUser,
  Whitelist,
} from "../database/firestore_requests";
import { mapDocToUser } from "../database/mapping_methods";
import {
  Container,
  CssBaseline,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";
import { ArrowBack, Block, Delete, InsertEmoticon } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import { green, orange, red } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { USER_ROLES } from "../config/constants";
import { UserContext } from "../context/UserContext";

//USER DETAILS PAGE : MANAGE ROLE SELECTION, BLACK LIST, DELETION, BEGINNERS ATTRIBUTION
export default function UserDetails({ match }) {
  const [t] = useTranslation("common");
  const { id } = match.params;
  const [user, setUser] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteUserPodsOpen, setDeleteUserPodsOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [expert_id, setExpert_id] = useState("NA");
  const [expertsArr, setExpertsArr] = useState([]);
  const [primaryArr, setPrimaryArr] = useState([]);

  const { users } = useContext(UserContext);

  // Load users that can be attributed
  useEffect(() => {
    const beginners = users.filter((item) => item.role === "Beginner");
    const advanced_users = users.filter((item) => item.role === "Advanced");
    const primary_users = beginners.concat(advanced_users);
    const primaryInfo = Object.fromEntries(
      primary_users.map(({ user_id, username, email, role }) => [
        user_id,
        { username, email, role },
      ])
    );
    setPrimaryArr(primaryInfo);
    const beginnersNotAttr = users.filter(
      (item) =>
        item.role === "Beginner" &&
        (item.expert === undefined || item.expert === "NA")
    );
    const advancedNotAttr = users.filter(
      (item) =>
        item.role === "Advanced" &&
        (item.expert === undefined || item.expert === "NA")
    );
    const primaryNotAttr = beginnersNotAttr.concat(advancedNotAttr);
    setLeft(primaryNotAttr.map((item) => item.user_id));
    let expertsAttr = users.filter(
      (item) =>
        item.role === USER_ROLES.EXPERT && item.attributed_users !== undefined
    );
    setExpertsArr(expertsAttr);
  }, [users]);

  // Load user's attributed volunteers
  useEffect(() => {
    const unsubscribe = getUser(
      {
        next: (documentSnapshot) => {
          let userFirestore = mapDocToUser(documentSnapshot);
          setUser(userFirestore);

          setRole(userFirestore.role);
          setUsername(userFirestore.username);

          if (userFirestore.attributed_users === undefined) {
            setRight([]);
          } else if (userFirestore.attributed_users.length === 0) {
            setRight([]);
          } else {
            setRight(userFirestore.attributed_users);
          }
          if (userFirestore.expert === undefined) {
            setExpert_id("NA");
          } else if (userFirestore.expert === "NA") {
            setExpert_id("NA");
          } else {
            setExpert_id(userFirestore.expert);
          }
          if (
            userFirestore.role === "Administrator" ||
            userFirestore.role === "Deleted"
          ) {
            setBtnDisabled(true);
          }
        },
        error: (error) => {
          setUser(undefined);
        },
      },
      id
    );
    return () => unsubscribe();
  }, [id, setUser]);

  //Drop down list
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
    },
    root: {
      margin: "auto",
    },
    paper: {
      width: 340,
      height: 230,
      overflow: "auto",
    },
    button_transfer: {
      margin: theme.spacing(0.5, 0),
    },
  }));

  let [role, setRole] = useState(user.role || "");
  let [username, setUsername] = useState(user.username || "");
  let history = useHistory();
  const classes = useStyles();

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteUserPodsClose = () => {
    setDeleteUserPodsOpen(false);
  };

  async function handleModify() {
    const prev_role = user.role;
    let remove_flag = false;

    if (role === "" || role === undefined) {
      alert("You must select a role");
    } else {
      if (
        prev_role === USER_ROLES.BEGINNER &&
        (role === USER_ROLES.EXPERT || role === USER_ROLES.ADMINISTRATOR)
      ) {
        remove_flag = true;
      }
      if (
        prev_role === USER_ROLES.ADVANCED &&
        (role === USER_ROLES.EXPERT || role === USER_ROLES.ADMINISTRATOR)
      ) {
        remove_flag = true;
      }
      if (prev_role === USER_ROLES.EXPERT && role !== USER_ROLES.EXPERT) {
        setOpen(true);
      } else {
        if (remove_flag) {
          if (expert_id !== "NA") {
            await handleRemoveExpert();
          }
        }
        handleUpdateUser();
      }
    }
  }

  async function handleUpdateExpertUser() {
    handleClose();
    right.map((item_id) => removeExpertFromList(item_id));
    modifyAttributedOnExpertList([]);
    isDeleted ? handleDeleteList() : handleUpdateUser();
  }

  async function handleUpdateUser() {
    await updateUser(id, { role, username });
    alert(t("_userModified", { name: username }));
  }

  async function handleRemoveExpert() {
    removeExpertFromList(id);
    let attributedListArr = expertsArr.filter(
      (expert) => expert.user_id === expert_id
    );
    let expertsArrRemoved = attributedListArr[0].attributed_users;
    let index = expertsArrRemoved.indexOf(id);
    if (index !== -1) {
      expertsArrRemoved.splice(index, 1);
      removeAttributedOnExpertList(expertsArrRemoved);
    }
  }

  async function handleBlacklist() {
    await Blacklist(id);
  }

  async function handleWhitelist() {
    await Whitelist(id);
  }

  async function handleDeleteUser() {
    const user_role = user.role;
    setIsDeleted(true);
    if (user_role === USER_ROLES.EXPERT) {
      setOpen(true);
    }
    if (
      user_role === USER_ROLES.BEGINNER ||
      user_role === USER_ROLES.ADVANCED
    ) {
      if (expert_id !== "NA") {
        handleRemoveExpert();
      }
      handleDeleteList();
    }
  }

  async function handleDeleteUserPods() {
    setDeleteUserPodsOpen(true);
  }

  async function handleDeletePods() {
    setDeleteUserPodsOpen(false);
    var userPods = await getUserPods(id); //id = user id
    for (var i = 0; i < userPods.length; i++) {
      deletePod(userPods[i]);
    }
  }

  async function handleDeleteList() {
    setIsDeleted(false);
    await Deletelist(id);
  }

  async function removeExpertFromList(user_id) {
    await RemoveExpert(user_id);
  }

  async function modifyAttributedOnExpertList(id_array) {
    await ModifyAttributed(id, id_array);
  }

  async function addExpertToList(user_id) {
    await AddExpert(user_id, id);
  }

  async function removeAttributedOnExpertList(id_array) {
    await ModifyAttributed(expert_id, id_array);
  }

  // Transfer List
  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  const [checked, setChecked] = useState([]);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    left.map((item_id) => addExpertToList(item_id));
    modifyAttributedOnExpertList(right.concat(left));
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    leftChecked.map((item_id) => addExpertToList(item_id));
    modifyAttributedOnExpertList(right.concat(leftChecked));
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    rightChecked.map((item_id) => removeExpertFromList(item_id));
    const id_array = right;
    for (let i = 0; i < rightChecked.length; i++) {
      for (let j = 0; j < id_array.length; j++) {
        if (id_array[j] === rightChecked[i]) {
          id_array.splice(j, 1);
          j--;
        }
      }
    }
    modifyAttributedOnExpertList(id_array);
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    right.map((item_id) => removeExpertFromList(item_id));
    modifyAttributedOnExpertList([]);
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;
          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={`${primaryArr[value].role} | ${primaryArr[value].username} | ${primaryArr[value].email}`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<ArrowBack />}
        onClick={() => history.goBack()}
      >
        {t("_back")}
      </Button>
      <CssBaseline />
      <Container maxWidth="sm">
        <Typography
          component="div"
          style={{
            backgroundColor: "#cfe8fc",
            height: "100%",
            width: "100%",
            margin: "0px",
            padding: "0px",
            textAlign: "center",
          }}
        >
          {false ? (
            <h1>This user was not found</h1>
          ) : (
            <>
              <p>
                <strong>{t("_email")}</strong> : {user.email}
              </p>
              <TextField
                value={username}
                label={t("_username")}
                onChange={(e) => setUsername(e.target.value)}
                style={{ width: "300px" }}
              />
              <div>
                <FormControl className={classes.formControl}>
                  <InputLabel id="role-label">{t("_role")}</InputLabel>
                  <Select
                    labelId="role-label"
                    value={role}
                    onChange={handleChangeRole}
                    style={{ width: "300px", textAlign: "left" }}
                  >
                    <MenuItem value={USER_ROLES.BEGINNER}>
                      {t("_beginner")}
                    </MenuItem>
                    <MenuItem value={USER_ROLES.ADVANCED}>
                      {t("_advanced")}
                    </MenuItem>
                    <MenuItem value={USER_ROLES.EXPERT}>
                      {t("_expert")}
                    </MenuItem>
                    <MenuItem value={USER_ROLES.ADMINISTRATOR}>
                      {t("_administrator")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>

              <Button
                variant="contained"
                color="primary"
                size="medium"
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={handleModify}
                style={{ width: "300px" }}
              >
                {t("_saveModification")}
              </Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {isDeleted
                    ? t("_dialogTitleDeleteExpert")
                    : t("_dialogTitleChangeExpert")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("_dialogChangeExpert")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    {t("_cancel")}
                  </Button>
                  <Button
                    onClick={handleUpdateExpertUser}
                    color="primary"
                    autoFocus
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <Dialog
                open={deleteUserPodsOpen}
                onClose={handleDeleteUserPodsClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {t("_dialogDeleteUserPodsTitle")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {t("_dialogDeleteUserPods")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDeleteUserPodsClose} color="primary">
                    {t("_cancel")}
                  </Button>
                  <Button onClick={handleDeletePods} color="primary" autoFocus>
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
              <br />

              {user.blacklist === false ? (
                <Button
                  variant="contained"
                  style={{ backgroundColor: red[500], width: "300px" }}
                  className={classes.button}
                  startIcon={<Block />}
                  onClick={handleBlacklist}
                >
                  {t("_blockUser")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[500], width: "300px" }}
                  className={classes.button}
                  startIcon={<InsertEmoticon />}
                  onClick={handleWhitelist}
                >
                  {t("_unblockUser")}
                </Button>
              )}
              <br />
              <Button
                variant="contained"
                style={{ backgroundColor: orange[500], width: "300px" }}
                className={classes.button}
                startIcon={<Delete />}
                disabled={btnDisabled}
                onClick={handleDeleteUser}
              >
                {t("_deleteUser")}
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: red[500], width: "fit-content" }}
                className={classes.button}
                startIcon={<ClearIcon />}
                disabled={btnDisabled}
                onClick={handleDeleteUserPods}
              >
                {t("_deleteUserPods")}
              </Button>
            </>
          )}
        </Typography>
      </Container>
      <br />
      <Container>
        <Typography
          component="div"
          style={{
            height: "100%",
            width: "100%",
            margin: "0px",
            padding: "0px",
            textAlign: "center",
          }}
        >
          {[USER_ROLES.EXPERT, USER_ROLES.ADMINISTRATOR].includes(user.role) ? (
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              className={classes.root}
            >
              <Grid item>
                <h3>{t("_notAttributedUser")}</h3>
                {customList(left)}
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems="center">
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button_transfer}
                    onClick={handleAllRight}
                    disabled={left.length === 0}
                    aria-label="move all right"
                  >
                    ≫
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button_transfer}
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                  >
                    &gt;
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button_transfer}
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                  >
                    &lt;
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button_transfer}
                    onClick={handleAllLeft}
                    disabled={right.length === 0}
                    aria-label="move all left"
                  >
                    ≪
                  </Button>
                </Grid>
              </Grid>
              <Grid item>
                <h3>{t("_attributedUser")}</h3>
                {customList(right)}
              </Grid>
            </Grid>
          ) : (
            <h1>&nbsp;</h1>
          )}
        </Typography>
      </Container>
    </>
  );
}
