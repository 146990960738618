import {
  FormControlLabel,
  FormLabel,
  Paper,
  RadioGroup,
} from "@material-ui/core";
import {
  BlueRadio,
  GreenRadio,
  GreyRadio,
  OrangeRadio,
  RedRadio,
} from "../utils/RadioCustom";

export default function LevelButtons({ title, level, setLevel }) {
  const getRadio = (level) => {
    switch (level) {
      case 1:
        return <GreenRadio />;
      case 2:
        return <BlueRadio />;
      case 3:
        return <OrangeRadio />;
      case 4:
        return <RedRadio />;
      case 5:
        return <GreyRadio />;
      default:
        return <GreyRadio />;
    }
  };

  return (
    <Paper>
      <FormLabel component="legend">{title}</FormLabel>
      <RadioGroup
        row
        aria-label="position"
        style={{ justifyContent: "center" }}
      >
        {[1, 2, 3, 4, 5].map((l) => (
          <FormControlLabel
            key={l}
            checked={level === l}
            onChange={setLevel}
            control={getRadio(l)}
            value={l}
            label={`${l}`}
            labelPlacement="bottom"
          />
        ))}
      </RadioGroup>
    </Paper>
  );
}
