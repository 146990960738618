import {
  Button,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { CameraAlt, Check, Close, Remove } from "@material-ui/icons";
import moment from "moment";
import { POD_DATE_FIELD, POD_ID_FIELD, USER_FIELD } from "../utils/evaluations";
import { DATE_TIME_FORMAT_MOMENT_MINUTES } from "../config/constants";
import { useTranslation } from "react-i18next";
import {
  dangerDimensions,
  technicalDimensions,
} from "../dimensions/dimensionsSingleChoice";
import {
  equipment,
  helpForHands,
  otherDangerFactors,
  otherDimensions,
  otherTechnicalFactors,
  pathEnvironment,
} from "../dimensions/dimensionsMultipleChoice";

import _ from "lodash";

import "./CriteriaComparisonModal.css";

export default function CriteriaComparisonModal({
  criteriaModalOpen,
  toggleCriteriaModalOpen,
  togglePhotosModalOpen,
  groupedPODs,
  selectedPosition,
  missionPods,
  users,
}) {
  const [t] = useTranslation("common");

  const sortedPODs = groupedPODs[selectedPosition].sort((p1, p2) => {
    if (moment(p1[POD_DATE_FIELD]).isBefore(p2[POD_DATE_FIELD], "day"))
      return -1;
    else if (moment(p1[POD_DATE_FIELD]).isAfter(p2[POD_DATE_FIELD], "day"))
      return 1;

    return p1[USER_FIELD].localeCompare(p2[USER_FIELD]);
  });

  return (
    <Modal open={criteriaModalOpen} onClose={toggleCriteriaModalOpen}>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            width: "95%",
            height: "90%",
            backgroundColor: "white",
            boxShadow: "0px 0px 13px -3px black",
            padding: "1em",
            pointerEvents: "all",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0.5em",
              right: "0.5em",
            }}
          >
            <IconButton onClick={toggleCriteriaModalOpen}>
              <Close />
            </IconButton>
          </div>
          <Button
            startIcon={<CameraAlt />}
            variant="contained"
            color="primary"
            onClick={togglePhotosModalOpen}
            style={{ width: "20em" }}
          >
            {t("_comparePhotos")}
          </Button>
          <TableContainer component={Paper} style={{ marginTop: "2em" }}>
            <Table
              aria-label="simple table"
              style={{ tableLayout: "fixed" }}
              stickyHeader={true}
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t("_criteria")}</TableCell>
                  {sortedPODs.map((pod) => (
                    <TableCell key={pod.id}>
                      {
                        users.find(
                          (u) =>
                            u.user_id ===
                            missionPods.find((p) => p.id === pod[POD_ID_FIELD])
                              ?.fk_user_id
                        )?.username
                      }{" "}
                      -{" "}
                      {moment(pod[POD_DATE_FIELD]).format(
                        DATE_TIME_FORMAT_MOMENT_MINUTES
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <Criterium
                  field="level_technical"
                  label="_technicalLevel"
                  pods={sortedPODs}
                  missionPods={missionPods}
                />
                <Criterium
                  field="level_risk"
                  label="_riskLevel"
                  pods={sortedPODs}
                  missionPods={missionPods}
                />
                <TableRow>
                  <TableCell colSpan={sortedPODs.length + 1}>
                    <strong>
                      {t("_technicalDimensions", { ns: "mobile" })}
                    </strong>
                  </TableCell>
                </TableRow>
                {technicalDimensions.map((dimension) => (
                  <Criterium
                    key={dimension.fieldKey}
                    field={dimension.fieldKey}
                    label={dimension.placeholderKey}
                    pods={sortedPODs}
                    missionPods={missionPods}
                    options={dimension.options}
                  />
                ))}
                <TableRow>
                  <TableCell colSpan={sortedPODs.length + 1}>
                    <strong>
                      {t("_otherTechnicalFactors", { ns: "mobile" })}
                    </strong>
                  </TableCell>
                </TableRow>
                {otherTechnicalFactors.map((dimension) => (
                  <Criterium
                    key={dimension.fieldKey}
                    field={dimension.fieldKey}
                    label={dimension.label}
                    pods={sortedPODs}
                    missionPods={missionPods}
                    options={dimension.options}
                  />
                ))}
                <TableRow>
                  <TableCell colSpan={sortedPODs.length + 1}>
                    <strong>{t("_helpForHands", { ns: "mobile" })}</strong>
                  </TableCell>
                </TableRow>
                {helpForHands.map((dimension) => (
                  <Criterium
                    key={dimension.fieldKey}
                    field={dimension.fieldKey}
                    label={dimension.label}
                    pods={sortedPODs}
                    missionPods={missionPods}
                    options={dimension.options}
                  />
                ))}
                <TableRow>
                  <TableCell colSpan={sortedPODs.length + 1}>
                    <strong>{t("_dangerDimensions", { ns: "mobile" })}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
              {dangerDimensions.map((dimension) => (
                <Criterium
                  key={dimension.fieldKey}
                  field={dimension.fieldKey}
                  label={dimension.placeholderKey}
                  pods={sortedPODs}
                  missionPods={missionPods}
                  options={dimension.options}
                />
              ))}
              <TableRow>
                <TableCell colSpan={sortedPODs.length + 1}>
                  <strong>{t("_pathEnvironment", { ns: "mobile" })}</strong>
                </TableCell>
              </TableRow>
              {pathEnvironment.map((dimension) => (
                <Criterium
                  key={dimension.fieldKey}
                  field={dimension.fieldKey}
                  label={dimension.label}
                  pods={sortedPODs}
                  missionPods={missionPods}
                  options={dimension.options}
                />
              ))}
              <TableRow>
                <TableCell colSpan={sortedPODs.length + 1}>
                  <strong>{t("_otherDangerFactors", { ns: "mobile" })}</strong>
                </TableCell>
              </TableRow>
              {otherDangerFactors.map((dimension) => (
                <Criterium
                  key={dimension.fieldKey}
                  field={dimension.fieldKey}
                  label={dimension.label}
                  pods={sortedPODs}
                  missionPods={missionPods}
                  options={dimension.options}
                />
              ))}
              <TableRow>
                <TableCell colSpan={sortedPODs.length + 1}>
                  <strong>{t("_equipment", { ns: "mobile" })}</strong>
                </TableCell>
              </TableRow>
              {equipment.map((dimension) => (
                <Criterium
                  key={dimension.fieldKey}
                  field={dimension.fieldKey}
                  label={dimension.label}
                  pods={sortedPODs}
                  missionPods={missionPods}
                  options={dimension.options}
                />
              ))}
              <TableRow>
                <TableCell colSpan={sortedPODs.length + 1}>
                  <strong>{t("_otherDimensions", { ns: "mobile" })}</strong>
                </TableCell>
              </TableRow>
              {otherDimensions.map((dimension) => (
                <Criterium
                  key={dimension.fieldKey}
                  field={dimension.fieldKey}
                  label={dimension.label}
                  pods={sortedPODs}
                  missionPods={missionPods}
                  options={dimension.options}
                />
              ))}
            </Table>
          </TableContainer>
        </div>
      </div>
    </Modal>
  );
}

function Criterium({ field, label, pods, missionPods, options }) {
  const [t] = useTranslation("common");

  function renderValue(missionPod) {
    return !options ? (
      _.isBoolean(missionPod[field]) ? (
        missionPod[field] ? (
          <Check />
        ) : (
          <Remove />
        )
      ) : (
        missionPod[field]
      )
    ) : (
      t(options.find((o) => o.id === missionPod[field]).value, {
        ns: "mobile",
      })
    );
  }

  function selectClass(field) {
    let mappedPods = pods.map((p) => missionPods.find((mp) => mp.id === p.id));

    let allEqual = mappedPods.every((p) => p[field] === mappedPods[0][field]);

    if (allEqual) return "correct-row";
    else return "incorrect-row";
  }

  return (
    <TableRow className={selectClass(field)}>
      <TableCell component="th" scope="row">
        {t(label, { ns: "mobile" })}
      </TableCell>
      {pods.map((pod) => {
        let missionPod = missionPods.find((mp) => mp.id === pod.id);

        return (
          <TableCell key={missionPod.id}>{renderValue(missionPod)}</TableCell>
        );
      })}
    </TableRow>
  );
}
