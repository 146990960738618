import firebase from "firebase";
import "firebase/firestore";

//firebase configurations

const config = {
  apiKey: "AIzaSyBrXFvNEEBMPbQlRg2W8g9Ta2pB_fl1fxM",
  authDomain: "appcitizenscience.firebaseapp.com",
  projectId: "appcitizenscience",
  storageBucket: "appcitizenscience.appspot.com",
  messagingSenderId: "799801011475",
  appId: "1:799801011475:web:3e4b6f9fda507cbcb5bee6",
  measurementId: "G-12RTTHXXYF",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

export const db = firebase.firestore();

firebase
  .firestore()
  .enablePersistence()
  .catch((err) => {
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all the
      // features required to enable persistence
      // ...
    }
  });

export default firebase;
