import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AuthProvider } from "./navigation/Authentication";
import { PrivateRoute } from "./navigation/PrivateRoute";
import SignIn from "./screens/SignIn";
import UserDetails from "./components/UserDetails";
import PodDetails from "./components/PodDetails";
import { UserContext } from "./context/UserContext";
import { MissionContext } from "./context/MissionContext";
import firebase from "firebase";
import {
  getUser,
  missionsStream,
  usersFromExpert,
  usersStream,
} from "./database/firestore_requests";
import { Redirect } from "react-router-dom";
import { mapDocToUser } from "./database/mapping_methods";
import Home from "./screens/Home";
import NavSideBar from "./navigation/NavSideBar";
import Contact from "./screens/Contact";
import PodValidation from "./screens/PodValidation";
import Mission from "./screens/Mission";
import Missions from "./screens/Missions";
import UserManagement from "./screens/UserManagement";

import "./App.css";
import "./styles/Leaflet.css";
import ValidateMissions from "./screens/ValidateMission";
import { MISSION_STATUS, USER_ROLES } from "./config/constants";
import PodManagement from "./screens/PodManagement";
import CompareMissions from "./screens/CompareMissions";
import MissionComparison from "./screens/MissionComparison";
import FiltreImages from "./screens/FiltreImages";
import TrainingExam from "./screens/TrainingExam";

//ROUTES AND USER CONTEXT ARE SET HERE

function App() {
  // User Context
  const [userAuthenticated, setUserAuthenticated] = useState();
  const [user, setUser] = useState();
  const [users, setUsers] = useState([]);

  // Detect auth changes
  useEffect(() => {
    const subscriber = firebase.auth().onAuthStateChanged((userAuth) => {
      if (userAuth !== null) {
        setUserAuthenticated(userAuth);
      } else {
        setUserAuthenticated(undefined);
      }
    });
    return () => subscriber();
  }, []);

  // Get user infos
  useEffect(() => {
    if (userAuthenticated !== undefined) {
      const start = Date.now();

      const unsubscribe = getUser(
        {
          next: (querySnapshot) => {
            const end = Date.now();
            console.log("Fetching user took in ms", end - start);
            setUser(mapDocToUser(querySnapshot));
          },
          error: (error) => {
            setUser(null);
          },
        },
        userAuthenticated.uid
      );
      return () => unsubscribe();
    }
  }, [userAuthenticated]);

  // Load users list (only attributed for experts, all for admins)
  useEffect(() => {
    async function fetchUsers() {
      const observer = (querySnapshot) => {
        const usersFirestore = querySnapshot.docs
          .filter((d) => d.data() !== undefined)
          .map((d) => mapDocToUser(d));

        // Add "self" if not in the list
        if (!usersFirestore.find((u) => u.user_id === user.user_id))
          usersFirestore.push(user);

        usersFirestore.sort((a, b) => {
          return a.username.localeCompare(b.username);
        });

        setUsers(usersFirestore);
      };

      const error = (error) => {
        setUsers([]);
      };

      let unsubscribe;
      if (user.role === USER_ROLES.ADMINISTRATOR) {
        unsubscribe = usersStream({
          next: observer,
          error: error,
        });
      } else if (user.role === USER_ROLES.EXPERT) {
        unsubscribe = usersFromExpert(
          { next: observer, error: error },
          userAuthenticated.uid
        );
      }

      return () => unsubscribe();
    }

    if (userAuthenticated && user) fetchUsers();
  }, [userAuthenticated, user]);

  return (
    <AuthProvider>
      <UserContext.Provider
        value={{
          userAuthenticated: userAuthenticated,
          user: user,
          setUserAuthenticated: setUserAuthenticated,
          setUser: setUser,
          users: users,
          setUsers: setUsers,
        }}
      >
        <BrowserRouter>
          <Switch>
            <Route exact path="/signin" component={SignInContainer} />
            <Route component={DefaultContainer} />
          </Switch>
        </BrowserRouter>
      </UserContext.Provider>
    </AuthProvider>
  );
}

function SignInContainer() {
  return (
    <>
      <Route exact path="/" render={() => <Redirect to="/signin" />} />
      <Route path="/signin" component={SignIn} />
    </>
  );
}

function DefaultContainer() {
  const { users } = useContext(UserContext);

  // Mission Context
  const [missions, setMissions] = useState(null);
  const [referenceMissions, setReferenceMissions] = useState(null);
  const [hideValidated, setHideValidated] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("due_date");
  const [orderComparison, setOrderComparison] = useState("asc");
  const [orderByComparison, setOrderByComparison] = useState("name");
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    if (!users) return;

    const unsubscribe = missionsStream(
      {
        next: (querySnapshot) => {
          let missionsFirestore = querySnapshot.docs
            .filter((d) => d.data() !== undefined)
            .map((d) => ({
              mission_id: d.id,
              ...d.data(),
            }));

          let referenceMissionsFirestore = missionsFirestore.filter((m) =>
            [MISSION_STATUS.TRAINING, MISSION_STATUS.EXAM].includes(m.status)
          );

          referenceMissionsFirestore.sort((m1, m2) =>
            m1.name.localeCompare(m2.name)
          );

          missionsFirestore = missionsFirestore.filter(
            (m) =>
              ![MISSION_STATUS.TRAINING, MISSION_STATUS.EXAM].includes(m.status)
          );

          missionsFirestore.sort((a, b) => {
            return a.due_date > b.due_date;
          });

          setMissions(missionsFirestore);
          setReferenceMissions(referenceMissionsFirestore);
        },
        error: (error) => {
          setMissions([]);
        },
      },
      hideValidated ? [["status", "!=", MISSION_STATUS.VALIDATED]] : null
    );
    return () => unsubscribe();
  }, [hideValidated, users]);

  return (
    <MissionContext.Provider
      value={{
        missions,
        setMissions,
        referenceMissions,
        setReferenceMissions,
        hideValidated,
        setHideValidated,
        order,
        orderBy,
        orderComparison,
        orderByComparison,
        setOrder,
        setOrderBy,
        setOrderComparison,
        setOrderByComparison,
        filterText,
        setFilterText,
      }}
    >
      <NavSideBar />
      <PrivateRoute exact path="/" component={Home} />
      <PrivateRoute exact path="/contact" component={Contact} />
      <PrivateRoute path="/user/:id" component={UserDetails} />
      <PrivateRoute path="/pod/:id" component={PodDetails} />
      {/* Expert Routes */}
      <PrivateRoute exact path="/pod-validation" component={PodValidation} />
      <PrivateRoute
        exact
        path="/pod-validation/:id"
        component={PodValidation}
      />
      <PrivateRoute exact path="/create-mission" component={Mission} />
      <PrivateRoute exact path="/update-mission/:id" component={Mission} />
      <PrivateRoute
        exact
        path="/validate-mission/:id/:podID?"
        component={ValidateMissions}
      />
      <PrivateRoute exact path="/missions" component={Missions} />
      <PrivateRoute
        exact
        path="/training-exam/:userID?/:missionID?"
        component={TrainingExam}
      />
      {/* Admin Routes */}
      <PrivateRoute exact path="/user-management" component={UserManagement} />
      <PrivateRoute exact path="/pod-management" component={PodManagement} />
      <PrivateRoute exact path="/filtre-images" component={FiltreImages} />
      <PrivateRoute
        exact
        path="/compare-missions"
        component={CompareMissions}
      />
      <PrivateRoute
        exact
        path="/mission-comparison"
        component={MissionComparison}
      />
    </MissionContext.Provider>
  );
}

export default App;
