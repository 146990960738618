import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useHistory } from "react-router-dom";
import "../App.css";
import { useTranslation } from "react-i18next";
import { USER_ROLES } from "../config/constants";
import { UserContext } from "../context/UserContext";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: "100%",
  },
});
const columns = [
  { id: "user_id", label: "_userid", minWidth: 170 },
  { id: "username", label: "_username", minWidth: 100 },
  {
    id: "role",
    label: "_role",
    minWidth: 170,
  },
  {
    id: "email",
    label: "_email",
    minWidth: 170,
    align: "justify",
  },
];

//THIS TABLE DISPLAYS USERS
export default function UsersTable() {
  const [t] = useTranslation("common");
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  let history = useHistory();

  const { users } = useContext(UserContext);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleNavigation = (id) => {
    history.push({
      pathname: `/user/${id}`,
    });
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#8ab6d6",
                  }}
                >
                  {t(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {users
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    onClick={() => handleNavigation(user.user_id)}
                    key={user.user_id}
                  >
                    {columns.map((column) => {
                      const value = user[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.label === "_role"
                            ? (() => {
                                switch (value) {
                                  case USER_ROLES.ADMINISTRATOR:
                                    return t("_administrator");
                                  case USER_ROLES.BEGINNER:
                                    return t("_beginner");
                                  case USER_ROLES.EXPERT:
                                    return t("_expert");
                                  case USER_ROLES.ADVANCED:
                                    return t("_advanced");
                                  default:
                                    return value;
                                }
                              })()
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={users.length}
        labelRowsPerPage={t("_rowsPerPage")}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
