import { useMap } from "react-leaflet";
import { useEffect } from "react";

import * as GeoSearch from "leaflet-geosearch";

import "./MapSearch.css";

export default function MapSearch(props) {
  const map = useMap();

  useEffect(() => {
    const searchControl = new GeoSearch.GeoSearchControl({
      provider: props.provider,
      style: props.mapStyle,
      ...props,
    });

    map.addControl(searchControl);

    return () => map.removeControl(searchControl);
    // map.addControl(
    //   new L.Control.Search({
    //     url: "https://nominatim.openstreetmap.org/search?format=json&q={s}",
    //     jsonpParam: "json_callback",
    //     propertyName: "display_name",
    //     propertyLoc: ["lat", "lon"],
    //     marker: L.circleMarker([0, 0], { radius: 30 }),
    //     autoCollapse: true,
    //     autoType: false,
    //     minLength: 2,
    //   })
    // );
  }, [props, map]);

  return null;
}
