import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import "./DragDropFile.css";

export default function DragDropFile({ addOrDelete, callbackFunction }) {
  //Translation
  const [t] = useTranslation();

  //Drag state
  const [dragActive, setDragActive] = useState(false);

  //Ref
  const inputRef = useRef(null);

  //Handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  //Triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      addOrDelete == "add"
    ) {
      callbackFunction(e.dataTransfer.files);
    }
    if (addOrDelete == "delete") {
      let html = e.dataTransfer.getData("text/html");
      let url = new DOMParser()
        .parseFromString(html, "text/html")
        .querySelector("img").src;
      callbackFunction(url);
    }
  };

  //Triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0] && addOrDelete == "add") {
      callbackFunction(e.target.files);
    }
  };

  //Triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        multiple={true}
        onChange={handleChange}
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        <div>
          {addOrDelete == "add" ? (
            <>
              <AddBoxIcon />
              <p>{t("_dragDropImageAdd")}</p>

              <button className="upload-button" onClick={onButtonClick}>
                {t("_uploadImage")}
              </button>
            </>
          ) : (
            <>
              <DeleteForeverIcon />
              <p>{t("_dragDropImageDelete")}</p>
            </>
          )}
        </div>
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </form>
  );
}
