import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink, withRouter } from "react-router-dom";
import { AdminLinks, ExpertLinks } from "./NavbarLinks";

import {
  AppBar,
  Drawer,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { HeaderComponent, LogoutComponent } from "../utils/NameManagement";
import { UserContext } from "../context/UserContext";
import Divider from "@material-ui/core/Divider";
import { useTranslation } from "react-i18next";
import { USER_ROLES } from "../config/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    width: 300,
  },
  userInfo: {
    marginBottom: -10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

//DISPLAY THE NAVBAR ACCORDING TO THE ROLE WITH THEIR OWN ROUTES ADMIN-EXPERT

const NavSideBar = () => {
  const classes = useStyles();
  const [drawerOpened, setDrawerOpened] = useState(false);
  const toggleDrawer = (booleanValue) => () => {
    setDrawerOpened(booleanValue);
  };
  const [t] = useTranslation("common");

  const LinkMenuList = withRouter((props) => {
    const { location } = props;

    return (
      <>
        {dbUser.user.role === USER_ROLES.ADMINISTRATOR ? (
          <MenuItems routes={AdminLinks} location={location} />
        ) : dbUser.user.role === USER_ROLES.EXPERT ? (
          <MenuItems routes={ExpertLinks} location={location} />
        ) : null}
      </>
    );
  });

  let dbUser = useContext(UserContext);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <HeaderComponent />
          </Typography>

          <LogoutComponent />
        </Toolbar>
      </AppBar>
      <Drawer
        classes={{ paper: classes.drawer }}
        anchor="left"
        open={drawerOpened}
        onClose={toggleDrawer(false)}
      >
        <div onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          {dbUser && dbUser.user && (
            <>
              <h2 className={classes.userInfo}>{t(dbUser.user.role)}</h2>
              <h4 className={classes.userInfo}>{dbUser.user.username}</h4>
              <h4 className={classes.userInfo} style={{ marginBottom: 20 }}>
                {dbUser.user.email}
              </h4>
            </>
          )}
          <Divider />
          <LinkMenuList />
        </div>
      </Drawer>
    </div>
  );
};

function MenuItems({ routes, location }) {
  const [t] = useTranslation("common");

  const activeRoute = (routeName, pathName) => {
    return pathName === routeName;
  };

  return (
    <MenuList>
      {routes.map((route) => {
        return (
          <NavLink
            to={route.path}
            style={{ textDecoration: "none" }}
            key={route.path}
          >
            <MenuItem selected={activeRoute(route.path, location.pathname)}>
              <ListItemText primary={t(route.sidebarName)} />
            </MenuItem>
          </NavLink>
        );
      })}
    </MenuList>
  );
}

export default withRouter(NavSideBar);
