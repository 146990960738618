import PodValidationTable from "../components/PodValidationTable";

//This screen calls the Pod Validation table

export default function PodValidation() {
  return (
    <div>
      <PodValidationTable />
    </div>
  );
}
