import { Button, IconButton, makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/UserContext";
import SyncIcon from "@material-ui/icons/Sync";
import { MissionContext } from "../context/MissionContext";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import EnhancedTableHead from "../components/EnhancedTableHead";
import TableBody from "@material-ui/core/TableBody";
import { getComparator } from "../utils/comparator";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment/moment";
import {
  DATE_FORMAT_MOMENT,
  DATE_TIME_FORMAT_MOMENT,
  DATE_TIME_FORMAT_MOMENT_MINUTES,
  MAP_TILE_URL,
} from "../config/constants";
import {
  getEvaluatedPods,
  getMissionPods,
  getMissionUserScore,
} from "../database/firestore_requests";
import TableContainer from "@material-ui/core/TableContainer";
import { useParams } from "react-router-dom";
import _ from "lodash";
import TableHead from "@material-ui/core/TableHead";
import { POD_DATE_FIELD, POD_ID_FIELD, USER_FIELD } from "../utils/evaluations";
import CompareToReference from "../components/CompareToReference";
import { MapContainer, Marker, ScaleControl, TileLayer } from "react-leaflet";
import Segment from "../components/map/Segment";

import { CloudDownload } from "@material-ui/icons";
import {
  dangerDimensions,
  technicalDimensions,
} from "../dimensions/dimensionsSingleChoice";
import icons from "leaflet-color-number-markers";
import CenterOnSegments from "../map/CenterOnSegments";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
  equipment,
  helpForHands,
  otherDangerFactors,
  otherDimensions,
  otherTechnicalFactors,
  pathEnvironment,
} from "../dimensions/dimensionsMultipleChoice";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  rotateIcon: {
    animation: "spin 3s linear infinite",
    animationDirection: "reverse",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
}));

export default function TrainingExam() {
  const [t] = useTranslation("common");

  const history = useHistory();

  const { userID, missionID } = useParams();

  const { referenceMissions } = useContext(MissionContext);

  const { users } = useContext(UserContext);

  const [selectedUser, setSelectedUser] = useState(null);

  const [center, setCenter] = useState(null);

  const classes = useStyles();

  const [referencePods, setReferencePods] = useState(null);

  const [evaluatedPods, setEvaluatedPods] = useState(null);

  const [currentReferencePod, setCurrentReferencePod] = useState(null);

  const [missionScore, setMissionScore] = useState(null);

  const mapRef = useRef();

  const referencePodsByMission = useMemo(() => {
    if (referencePods) {
      return _.groupBy(referencePods, "mission_id");
    } else return null;
  }, [referencePods]);

  const currentUser = useMemo(() => {
    if (!users || !userID) return null;

    return users.find((u) => u.user_id === userID);
  }, [users, userID]);

  const currentMission = useMemo(() => {
    if (!referenceMissions || !missionID) return null;

    return referenceMissions.find((m) => m.mission_id === missionID);
  }, [referenceMissions, missionID]);

  const formattedSegments = useMemo(() => {
    if (!currentMission) return [];

    return currentMission.segments.map((s) => ({
      ...s,
      coordinates: s.coordinates.map((c) => [c.latitude, c.longitude]),
    }));
  }, [currentMission]);

  const currentReferencePods = useMemo(() => {
    if (!currentMission || !referencePodsByMission) return [];

    return referencePodsByMission[currentMission.mission_id].sort(
      (p1, p2) => p1.date - p2.date
    );
  }, [currentMission, referencePodsByMission]);

  const currentEvaluatedPod = useMemo(() => {
    if (!evaluatedPods || !currentReferencePod) return null;

    let evaluatedPod = evaluatedPods.find(
      (p) => p.fk_reference_pod === currentReferencePod.id
    );

    console.log(
      "Current Evaluated POD",
      evaluatedPod?.id,
      "for Reference POD",
      currentReferencePod?.id
    );

    return evaluatedPod;
  }, [evaluatedPods, currentReferencePod]);

  const getScore = async (userID, missionID) => {
    let score = await getMissionUserScore(missionID, userID);
    setMissionScore(score);
  };

  const userMissionScore = useMemo(() => {
    if (!missionID || !userID) {
      return null;
    }
    getScore(userID, missionID);
  }, [userID, missionID]);

  // Table sorting options
  const [orderUsers, setOrderUsers] = useState("username");
  const [orderByUsers, setOrderByUsers] = useState("asc");
  const [orderTraining, setOrderTraining] = useState("asc");
  const [orderByTraining, setOrderByTraining] = useState("name");

  useEffect(() => {
    async function fetchEvaluatedPods() {
      let evaluatedPods = await getEvaluatedPods(userID);
      setEvaluatedPods(
        evaluatedPods.docs.map((d) => ({ id: d.id, ...d.data() }))
      );
    }

    if (userID) {
      fetchEvaluatedPods();
    }
  }, [userID]);

  useEffect(() => {
    async function fetchReferencePODs() {
      if (referenceMissions) {
        let referencePODs = [];

        for (let referenceMission of referenceMissions) {
          let podDocs = await getMissionPods(referenceMission.mission_id);

          referencePODs = [
            ...referencePODs,
            ...podDocs.docs.map((p) => ({ id: p.id, ...p.data() })),
          ];
        }

        setReferencePods(referencePODs);
      }
    }

    fetchReferencePODs();
  }, [referenceMissions]);

  // Reset reference POD on user/mission change
  useEffect(() => {
    setCurrentReferencePod(null);
  }, [userID, missionID]);

  const headCellsUsers = [
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: t("_username"),
    },
  ];

  const headCellsMissions = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("_mission"),
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: `${t("_training")}/${t("_exam")}`,
    },
    {
      id: "nbEvaluated",
      numeric: false,
      disablePadding: false,
      label: `# ${t("_evaluatedPods")}`,
    },
  ];

  // TODO - Check if it's easy to display only users that have done some training/exam mission
  const filteredUsers = useMemo(() => {
    return users;
  }, [users, referenceMissions]);

  const goToUser = (user) => {
    history.push(`/training-exam/${user.user_id}`);
  };

  const goToMission = (mission) => {
    history.push(`/training-exam/${currentUser.user_id}/${mission.mission_id}`);
  };

  const handleRequestSort = (
    event,
    property,
    orderBy,
    order,
    setOrderBy,
    setOrder
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleRequestSortMissions = (event, property) => {
    const isAsc = orderByTraining === property && orderTraining === "asc";
    setOrderTraining(isAsc ? "desc" : "asc");
    setOrderByTraining(property);
  };

  const getNbEvaluatedPODsMission = (missionID) => {
    if (!referencePods || !evaluatedPods) return 0;

    return referencePods
      .filter((p) => p.mission_id === missionID)
      .reduce((acc, curr) => {
        if (evaluatedPods.map((p) => p.fk_reference_pod).includes(curr.id))
          return acc + 1;
        return acc;
      }, 0);
  };

  const handleClickPrev = () => {
    handleNavigate(-1);
  };

  const handleClickNext = () => {
    handleNavigate(1);
  };

  const handleNavigate = (direction) => {
    let currentPODIndex = currentReferencePod
      ? currentReferencePods.findIndex((p) => p.id === currentReferencePod.id)
      : -1;

    if (currentPODIndex <= 0 && direction < 0) return;
    if (currentPODIndex === currentReferencePods.length - 1 && direction > 0)
      return;

    setCurrentReferencePod(currentReferencePods[currentPODIndex + direction]);
  };

  const saveImage = async (url) => {
    let imageResponse = await fetch(url);
    let imageBlob = await imageResponse.blob();

    saveAs(
      imageBlob,
      url.split("/").pop().split("#")[0].split("?")[0].replace("pods%2F", "")
    );
  };

  if (!referenceMissions)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <SyncIcon className={classes.rotateIcon} fontSize="large" />
      </div>
    );

  // List users if no user is selected
  if (!userID)
    return (
      <div
        className={classes.root}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <h2>{t("_reviewTrainingExam")}</h2>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <EnhancedTableHead
              headCells={headCellsUsers}
              order={orderUsers}
              orderBy={orderByUsers}
              onRequestSort={(e, p) =>
                handleRequestSort(
                  e,
                  p,
                  orderByUsers,
                  orderUsers,
                  setOrderByUsers,
                  setOrderUsers
                )
              }
            />
            <TableBody>
              {filteredUsers
                .sort(getComparator(orderUsers, orderByUsers))
                .map((user) => {
                  return (
                    <TableRow
                      style={{ backgroundColor: "#8ab6d6", cursor: "pointer" }}
                      key={user.email}
                      onClick={() => goToUser(user)}
                    >
                      <TableCell>{user.username}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );

  // List missions if user is selected but not mission
  if (!missionID)
    return (
      <div
        className={classes.root}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <h2>
          {t("_reviewTrainingExam")} -{" "}
          {users.length > 0 && currentUser && currentUser.username}
        </h2>

        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <EnhancedTableHead
              headCells={headCellsMissions}
              order={orderTraining}
              orderBy={orderByTraining}
              onRequestSort={(e, p) =>
                handleRequestSort(
                  e,
                  p,
                  orderByTraining,
                  orderTraining,
                  setOrderByTraining,
                  setOrderTraining
                )
              }
            />

            <TableBody>
              {referenceMissions &&
                referenceMissions
                  .sort(getComparator(orderTraining, orderByTraining))
                  .map((mission) => {
                    return (
                      <TableRow
                        style={{
                          backgroundColor: "#8ab6d6",
                          cursor: "pointer",
                        }}
                        key={mission.mission_id}
                        onClick={() => goToMission(mission)}
                      >
                        <TableCell>{mission.name}</TableCell>
                        <TableCell>{t(`_${mission.status}`)}</TableCell>
                        <TableCell>
                          {getNbEvaluatedPODsMission(mission.mission_id)} /{" "}
                          {referencePodsByMission &&
                          referencePodsByMission[mission.mission_id]
                            ? referencePodsByMission[mission.mission_id].length
                            : "?"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );

  // Show training/exam mission evaluation when user & mission are selected
  return (
    <div
      className={classes.root}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h2>
        {t("_reviewTrainingExam")} -{" "}
        {users.length > 0 && currentUser && currentUser.username} -{" "}
        {currentMission && currentMission.name}
      </h2>

      {missionScore && missionScore.maxScore && missionScore.score && (
        <h3 style={{ marginBlockStart: 0 }}>
          {t("_missionScore")} {missionScore.score} / {missionScore.maxScore} ={" "}
          {((missionScore.score / missionScore.maxScore) * 100)
            .toFixed(2)
            .replace(/\.0+$/, "")}
          %
        </h3>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flex: "1 1 0px",
          overflowY: "hidden",
        }}
      >
        <div
          style={{ flexGrow: 1, width: 0, height: "100%", overflowY: "hidden" }}
        >
          <div style={{ textAlign: "center" }}>
            {t("_selectPosition")}
            {evaluatedPods && referencePods ? (
              <div>
                <IconButton onClick={handleClickPrev}>
                  <ArrowLeftIcon />
                </IconButton>
                {currentReferencePod ? `#${currentReferencePod.pod_id}` : "---"}
                <IconButton onClick={handleClickNext}>
                  <ArrowRightIcon />
                </IconButton>
              </div>
            ) : (
              <p>
                <SyncIcon className={classes.rotateIcon} />
              </p>
            )}
          </div>
          <MapContainer
            id="leafletMap"
            className={classes.mapContainer}
            whenCreated={(mapInstance) => {
              mapRef.current = mapInstance;
            }}
            style={{ borderRadius: 0, height: "90%" }}
          >
            <TileLayer url={MAP_TILE_URL} maxNativeZoom={19} maxZoom={25} />
            <ScaleControl position="topright" imperial={false} maxWidth={200} />
            {formattedSegments.map((s) => (
              <Segment
                key={s.id}
                id={s.id}
                selected={true}
                coordinates={s.coordinates}
                editing={false}
              />
            ))}
            {currentReferencePods.map((pod, n) => (
              <Marker
                key={pod.id}
                position={[
                  pod.geo_position.latitude,
                  pod.geo_position.longitude,
                ]}
                icon={
                  currentReferencePod?.id === pod.id
                    ? icons.red.numbers[n + 1]
                    : icons.blue.numbers[n + 1]
                }
                eventHandlers={{
                  click: () => {
                    setCurrentReferencePod(pod);
                  },
                }}
              />
            ))}
            {/* Component to fit bounds of the map */}
            <CenterOnSegments
              segments={formattedSegments}
              setCenter={setCenter}
              padding={10}
            />
          </MapContainer>
        </div>
        <div
          style={{
            flexGrow: 2,
            width: 0,
            overflowY: "scroll",
            display: "flex",
            flexDirection: "column",
            flexBasis: 0,
          }}
        >
          {currentReferencePod && (
            <>
              <div style={{ flexGrow: 1, height: 0 }}>
                {missionScore?.pods.find(
                  (pod) => pod.fk_pod == currentEvaluatedPod?.id
                ) && (
                  <h4
                    style={{
                      marginLeft: "10px",
                      marginBlockStart: 0,
                      marginBlockEnd: 0,
                    }}
                  >
                    {t("_podScore")}{" "}
                    {
                      missionScore.pods.find(
                        (pod) => pod.fk_pod == currentEvaluatedPod?.id
                      )?.score
                    }{" "}
                    /{" "}
                    {
                      missionScore.pods.find(
                        (pod) => pod.fk_pod == currentEvaluatedPod?.id
                      )?.scoreTotal
                    }
                  </h4>
                )}
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    pointerEvents: "none",
                  }}
                >
                  <div
                    style={{
                      width: "95%",
                      height: "90%",
                      backgroundColor: "white",
                      pointerEvents: "all",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div className="columns">
                      {[currentEvaluatedPod, currentReferencePod].map(
                        (pod, index) => (
                          <div className="column">
                            <h4 style={{ textAlign: "center" }}>
                              {index === 0
                                ? currentUser.username
                                : t("_referenceMission")}{" "}
                              -{" "}
                              {moment(pod?.date.toDate()).format(
                                DATE_FORMAT_MOMENT
                              )}
                            </h4>
                            <div className="photos">
                              {pod?.img_download_urls
                                .filter((i) => i)
                                .map((i) => (
                                  <a
                                    href={i}
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      await saveImage(i);
                                    }}
                                    title={t("_downloadPhoto")}
                                    key={i}
                                    style={{
                                      display: "block",
                                      position: "relative",
                                    }}
                                    className="photo-link"
                                  >
                                    <CloudDownload
                                      style={{
                                        width: "48px",
                                        height: "48px",
                                      }}
                                      className="download-photo"
                                    />
                                    <img
                                      className="photo-preview"
                                      src={i}
                                      alt={pod.id}
                                      style={{ maxWidth: "200px" }}
                                    />
                                  </a>
                                ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <TableContainer
                component={Paper}
                style={{ maxHeight: "100%", flexGrow: 1, height: 0 }}
              >
                <Table
                  aria-label="simple table"
                  style={{ tableLayout: "fixed" }}
                  stickyHeader={true}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("_criteria")}</TableCell>
                      <TableCell>
                        {currentUser &&
                          evaluatedPods?.length > 0 &&
                          `${currentUser.username} - ${moment(
                            evaluatedPods[0].date.toDate()
                          ).format(DATE_FORMAT_MOMENT)}`}
                      </TableCell>
                      <TableCell>{t("_referenceMission")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <CompareToReference
                      field="level_technical"
                      label="_technicalLevel"
                      pod={currentEvaluatedPod}
                      referencePod={currentReferencePod}
                    />
                    <CompareToReference
                      field="level_risk"
                      label="_riskLevel"
                      pod={currentEvaluatedPod}
                      referencePod={currentReferencePod}
                    />
                    <TableRow>
                      <TableCell colSpan={3}>
                        <strong>
                          {t("_technicalDimensions", { ns: "mobile" })}
                        </strong>
                      </TableCell>
                    </TableRow>
                    {technicalDimensions.map((dimension) => (
                      <CompareToReference
                        key={dimension.fieldKey}
                        field={dimension.fieldKey}
                        label={dimension.placeholderKey}
                        pod={currentEvaluatedPod}
                        referencePod={currentReferencePod}
                        options={dimension.options}
                      />
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <strong>
                          {t("_otherTechnicalFactors", { ns: "mobile" })}
                        </strong>
                      </TableCell>
                    </TableRow>
                    {otherTechnicalFactors.map((dimension) => (
                      <CompareToReference
                        key={dimension.fieldKey}
                        field={dimension.fieldKey}
                        label={dimension.label}
                        pod={currentEvaluatedPod}
                        referencePod={currentReferencePod}
                        options={dimension.options}
                      />
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <strong>{t("_helpForHands", { ns: "mobile" })}</strong>
                      </TableCell>
                    </TableRow>
                    {helpForHands.map((dimension) => (
                      <CompareToReference
                        key={dimension.fieldKey}
                        field={dimension.fieldKey}
                        label={dimension.label}
                        pod={currentEvaluatedPod}
                        referencePod={currentReferencePod}
                        options={dimension.options}
                      />
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <strong>
                          {t("_dangerDimensions", { ns: "mobile" })}
                        </strong>
                      </TableCell>
                    </TableRow>
                    {dangerDimensions.map((dimension) => (
                      <CompareToReference
                        key={dimension.fieldKey}
                        field={dimension.fieldKey}
                        label={dimension.placeholderKey}
                        pod={currentEvaluatedPod}
                        referencePod={currentReferencePod}
                        options={dimension.options}
                      />
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <strong>
                          {t("_pathEnvironment", { ns: "mobile" })}
                        </strong>
                      </TableCell>
                    </TableRow>
                    {pathEnvironment.map((dimension) => (
                      <CompareToReference
                        key={dimension.fieldKey}
                        field={dimension.fieldKey}
                        label={dimension.label}
                        pod={currentEvaluatedPod}
                        referencePod={currentReferencePod}
                        options={dimension.options}
                      />
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <strong>
                          {t("_otherDangerFactors", { ns: "mobile" })}
                        </strong>
                      </TableCell>
                    </TableRow>
                    {otherDangerFactors.map((dimension) => (
                      <CompareToReference
                        key={dimension.fieldKey}
                        field={dimension.fieldKey}
                        label={dimension.label}
                        pod={currentEvaluatedPod}
                        referencePod={currentReferencePod}
                        options={dimension.options}
                      />
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <strong>{t("_equipment", { ns: "mobile" })}</strong>
                      </TableCell>
                    </TableRow>
                    {equipment.map((dimension) => (
                      <CompareToReference
                        key={dimension.fieldKey}
                        field={dimension.fieldKey}
                        label={dimension.label}
                        pod={currentEvaluatedPod}
                        referencePod={currentReferencePod}
                        options={dimension.options}
                      />
                    ))}
                    <TableRow>
                      <TableCell colSpan={3}>
                        <strong>
                          {t("_otherDimensions", { ns: "mobile" })}
                        </strong>
                      </TableCell>
                    </TableRow>
                    {otherDimensions.map((dimension) => (
                      <CompareToReference
                        key={dimension.fieldKey}
                        field={dimension.fieldKey}
                        label={dimension.label}
                        pod={currentEvaluatedPod}
                        referencePod={currentReferencePod}
                        options={dimension.options}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
