export function calculateCenter(coordinates) {
  let summedCoordinates = coordinates.reduce(
    (acc, curr) => {
      let [lat, lng] = curr;
      let [accLat, accLng] = acc;

      return [accLat + lat, accLng + lng];
    },
    [0, 0]
  );

  let [summedLat, summedLng] = summedCoordinates;

  return [summedLat / coordinates.length, summedLng / coordinates.length];
}
