import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  changeMissionStatus,
  getMission,
  getMissionPods,
  getUser,
  validatePOD,
  invalidatePOD,
  deleteMission,
} from "../database/firestore_requests";
import {
  MapContainer,
  Marker,
  ScaleControl,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import Segment from "../components/map/Segment";
import CenterOnSegments from "../map/CenterOnSegments";
import {
  MISSION_STATUS,
  DATE_FORMAT_MOMENT,
  MAP_TILE_URL,
  POD_MARKERS_MAP,
  POD_STATUS,
} from "../config/constants";
import haversine from "haversine-distance";
import { Alert } from "@material-ui/lab";
import moment from "moment";
import { blackIcon, violetIcon } from "../config/icons";
import { getPodName } from "../dimensions/dimensionsSingleChoice";
import { UserContext } from "../context/UserContext";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckCircle from "@material-ui/icons/CheckCircle";
import DeleteForever from "@material-ui/icons/DeleteForever";
import HighlightOff from "@material-ui/icons/HighlightOff";
import { blue, green, orange, red } from "@material-ui/core/colors";
import PodSelection from "../components/PodSelection";
import IconButton from "@material-ui/core/IconButton";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mapContainer: {
    height: "500px",
    borderRadius: 0,
  },
}));

export default function ValidateMission() {
  const [t] = useTranslation("common");
  const history = useHistory();
  const classes = useStyles();
  const { user } = useContext(UserContext);

  const [mission, setMission] = useState(null);
  const [missionPods, setMissionPods] = useState([]);
  const [volunteer, setVolunteer] = useState(null);
  const [creator, setCreator] = useState(null);

  const [center, setCenter] = useState(null);

  const [maxRiskMissionlevel, setMaxRiskMissionlevel] = useState(1);
  const [maxTechnicalMissionlevel, setMaxTechnicalMissionlevel] = useState(1);

  const [selectedPOD, setSelectedPOD] = useState(0);

  const params = useParams();

  const podID = params.podID;

  // Fetch mission
  useEffect(() => {
    async function fetchMission() {
      let missionDoc = await getMission(params.id);
      let mission = missionDoc.data();
      setMission(mission);
    }

    if (params.id) fetchMission();
  }, [params.id]);

  // Fetch mission PODs
  useEffect(() => {
    async function fetchMissionPODs() {
      let podDocs = await getMissionPods(params.id);
      let pods = podDocs.docs.map((p) => ({ id: p.id, ...p.data() }));
      setMissionPods(pods);
    }

    if (params.id) fetchMissionPODs();
  }, [params.id]);

  useEffect(() => {
    if (params.podID) {
      for (let i = 0; i < missionPods.length; i++) {
        if (missionPods[i].id == params.podID) {
          setSelectedPOD(i);
        }
      }
    }
  }, [missionPods, selectedPOD]);

  // Fetch volunteer
  useEffect(() => {
    async function fetchUser() {
      getUser((snapshot) => setVolunteer(snapshot.data()), mission.fk_user_id);
    }

    if (mission) fetchUser();
  }, [mission]);

  // Fetch creator
  useEffect(() => {
    async function fetchCreator() {
      getUser((snapshot) => setCreator(snapshot.data()), mission.fk_creator_id);
    }

    if (mission) fetchCreator();
  }, [mission]);

  //Compute the mission max level of danger and technique
  useEffect(() => {
    let maxLevelRisk = 1;
    let maxLevelTechnical = 1;
    for (let pod of missionPods) {
      if (pod.level_risk > maxLevelRisk) {
        maxLevelRisk = pod.level_risk;
      }
      if (pod.level_technical > maxLevelTechnical) {
        maxLevelTechnical = pod.level_technical;
      }
    }
    setMaxRiskMissionlevel(maxLevelRisk);
    setMaxTechnicalMissionlevel(maxLevelTechnical);
  }, [missionPods]);

  // Formatted segments
  const formattedSegments = useMemo(() => {
    if (!mission) return [];

    return mission.segments.map((s) => ({
      ...s,
      coordinates: s.coordinates.map((c) => [c.latitude, c.longitude]),
    }));
  }, [mission]);

  // Calculate total distance
  const totalDistance = useMemo(() => {
    const calculateDistance = (segment) => {
      let totalDistance = 0;
      for (let i = 0; i < segment.coordinates.length - 1; i++) {
        totalDistance +=
          haversine(segment.coordinates[i], segment.coordinates[i + 1]) / 1000;
      }

      return totalDistance;
    };

    if (formattedSegments.length === 0) return 0;

    return formattedSegments.reduce((acc, curr) => {
      return acc + calculateDistance(curr);
    }, 0);
  }, [formattedSegments]);

  const handleValidateClick = async () => {
    await changeMissionStatus(params.id, MISSION_STATUS.VALIDATED);
    history.push("/missions");
  };

  const handleCloneClick = async () => {
    history.push({
      pathname: `/create-mission`,
      state: {
        missionName: mission.name,
        dueDate: mission.due_date.toDate(),
        selectedSegments: mission.segments.map((s) => ({
          ...s,
          coordinates: s.coordinates.map((c) => [c.latitude, c.longitude]),
        })),
        fk_creator_id: user.user_id,
        center: center,
      },
    });
  };

  const validateAllPods = async () => {
    missionPods.forEach((element) => {
      validatePOD(element.id);
    });
  };

  const invalidateAllPods = async () => {
    missionPods.forEach((element) => {
      invalidatePOD(element.id);
    });
  };

  const delMission = async () => {
    deleteMission(params.id).then(() => history.goBack());
  };

  const goToNextPOD = () => {
    const nextIndex = (selectedPOD + 1) % missionPods.length;
    setSelectedPOD(nextIndex);
  };

  const goToPreviousPOD = () => {
    const previousIndex =
      (selectedPOD - 1 + missionPods.length) % missionPods.length;
    setSelectedPOD(previousIndex);
  };

  const detailsPOD = () => {
    history.push(
      `/validate-mission/${params.id}/${missionPods[selectedPOD].id}`
    );
    history.push({
      pathname: `/pod/${missionPods[selectedPOD].id}`,
    });
  };

  const renderValidation = () => {
    switch (mission.status) {
      case MISSION_STATUS.VALIDATED:
        return <Alert severity="success">{t("_missionValidated")}</Alert>;
      case MISSION_STATUS.STARTED:
      case MISSION_STATUS.STOPPED:
        return <Alert severity="warning">{t("_missionInProgress")}</Alert>;
      case MISSION_STATUS.TO_VALIDATE:
        return (
          <p>
            <Button
              variant="contained"
              color="primary"
              disabled={processedPODs.length !== missionPods.length}
              onClick={handleValidateClick}
            >
              {t("_validateMission")}
            </Button>{" "}
            {processedPODs.length !== missionPods.length && (
              <p>
                <small>{t("_processAllPODs")}</small>
              </p>
            )}
          </p>
        );
      default:
        return <p>???</p>;
    }
  };

  if (!mission || !volunteer || !creator) return <p>{t("_loading")}...</p>;

  const processedPODs = missionPods.filter(
    (m) => m.status !== POD_STATUS.TO_VALIDATE
  );

  return (
    <div
      className={classes.root}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h2>{t("_validateMission")}</h2>
      <div>
        <IconButton onClick={goToPreviousPOD} style={iconButtonStyles.icon}>
          <ArrowLeftIcon />
        </IconButton>
        <IconButton onClick={goToNextPOD} style={iconButtonStyles.icon}>
          <ArrowRightIcon />
        </IconButton>
        <Button onClick={detailsPOD}> POD #{selectedPOD + 1} </Button>
      </div>
      <div style={{ display: "flex", flexGrow: 1 }}>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            flex: 1,
          }}
        >
          <MapContainer
            id="leafletMap"
            className={classes.mapContainer}
            scrollWheelZoom="center"
            doubleClickZoom="center"
            style={{ flexGrow: 1 }}
          >
            <TileLayer url={MAP_TILE_URL} />
            <ScaleControl position="topright" imperial={false} maxWidth={200} />
            {formattedSegments.map((s) => (
              <Segment
                key={s.id}
                id={s.id}
                selected={true}
                coordinates={s.coordinates}
                editing={false}
              />
            ))}
            {missionPods.map((p, index) => (
              <Marker
                key={p.id}
                position={[p.geo_position.latitude, p.geo_position.longitude]}
                eventHandlers={{
                  click: () => {
                    setSelectedPOD(index);
                    history.push(`/validate-mission/${params.id}/${p.id}`);
                    history.push({
                      pathname: `/pod/${p.id}`,
                    });
                  },
                }}
                icon={
                  index == selectedPOD
                    ? violetIcon
                    : POD_MARKERS_MAP[p.status] ?? blackIcon
                }
              >
                <Tooltip permanent={false} key={`${p.id}-${p.id === podID}`}>
                  {
                    <div>
                      {p.name && (
                        <>
                          <span>
                            <strong>
                              {t(
                                getPodName().find((o) => o.id === p.name)
                                  ?.value,
                                {
                                  ns: "mobile",
                                }
                              )}
                            </strong>
                          </span>
                          <br />
                        </>
                      )}
                      {p.description && (
                        <>
                          <span>{p.description}</span>
                          <br />
                        </>
                      )}
                      <span>
                        {t("_status")} : {t(`_${p.status}`)}
                      </span>
                      <br />
                      {p.level_technical && (
                        <>
                          <span>
                            {t("_technicalLevel")} :
                            <strong>{p.level_technical}</strong>
                          </span>
                          <br />
                        </>
                      )}
                      {p.level_risk && (
                        <>
                          <span>
                            {t("_riskLevel")} : <strong>{p.level_risk}</strong>
                          </span>
                          <br />
                        </>
                      )}
                    </div>
                  }
                </Tooltip>
              </Marker>
            ))}
            {/* Component to fit bounds of the map */}
            <CenterOnSegments
              segments={formattedSegments}
              setCenter={setCenter}
            />
          </MapContainer>
        </div>
        <div style={{ flex: 1, padding: "0 0.5em" }}>
          <h3>{t("_missionDetails")}</h3>
          {formattedSegments.length === 0 && "---"}
          {formattedSegments.length > 0 && (
            <div>
              {mission.ref && (
                <p style={{ fontWeight: "bold" }}>{t("_missionRef")}</p>
              )}
              <p></p>
              <p>
                {t("_nbSegments")} : {formattedSegments.length}
              </p>
              <p>
                {t("_totalDistance")} : {totalDistance.toFixed(2)}km
              </p>
              <p>
                {t("_missionMaxTechnicalLevel")} : {maxTechnicalMissionlevel}
              </p>
              <p>
                {t("_missionMaxDangerLevel")} : {maxRiskMissionlevel}
              </p>
            </div>
          )}
          <h3>{t("_missionName")}</h3>
          <p>{mission.name || "---"}</p>
          <h3>{t("_selectedUser")}</h3>
          <p>
            {volunteer.username} ({volunteer.email})
          </p>
          <h3>{t("_dueDate")}</h3>
          <p>{moment(mission.due_date.toDate()).format(DATE_FORMAT_MOMENT)}</p>
          <h3>{t("_creator")}</h3>
          <p>
            {creator.username} ({creator.email})
          </p>
          <h3>{t("_pod", { count: 2 })}</h3>
          <p>
            {processedPODs.length}/{missionPods.length}{" "}
            {t("_pod", { count: 2 })} {t("_processed")}
          </p>
          <PodSelection
            missionID={params.id}
            pods={missionPods}
            podID={podID}
          />
          <div>{renderValidation()}</div>
          <br />
          <Button
            variant="contained"
            onClick={handleCloneClick}
            startIcon={<FileCopyIcon />}
            style={{ color: "white", backgroundColor: blue[500] }}
          >
            {t("_cloneMission")}
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            onClick={validateAllPods}
            startIcon={<CheckCircle />}
            style={{ color: "white", backgroundColor: green[500] }}
          >
            {t("_validateAllPods")}
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            onClick={invalidateAllPods}
            startIcon={<HighlightOff />}
            style={{ color: "white", backgroundColor: orange[500] }}
          >
            {t("_invalidateAllPods")}
          </Button>
          <br />
          <br />
          <Button
            variant="contained"
            onClick={delMission}
            startIcon={<DeleteForever />}
            style={{ color: "white", backgroundColor: red[500] }}
          >
            {t("_deleteMission")}
          </Button>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

const iconButtonStyles = {
  icon: {
    border: "2px solid #333",
    borderRadius: "8px",
    padding: "8px",
    marginLeft: "5px",
    marginRight: "5x",
    marginBottom: "10px",
    fontSize: "12px",
  },
};
