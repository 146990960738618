import { JsonProvider } from "leaflet-geosearch";

export class GeoAdminProvider extends JsonProvider {
  endpoint({ query, type }) {
    // Result: https://example.com/api/search?q=some%20address&f=json
    return this.getUrl(
      "https://api3.geo.admin.ch/rest/services/api/SearchServer",
      {
        searchText: query,
        type: "locations",
      }
    );
  }
  parse({ data }) {
    // Note that `data` is the raw result returned by the server. This
    // method should return data in the SearchResult format.
    return data.results.map((r) => ({
      x: r.attrs.lon,
      y: r.attrs.lat,
      label: r.attrs.label,
      bounds: null,
    }));
  }
  search(query) {
    if ("data" in query) {
      return Promise.resolve([query.data]);
    }
    return super.search(query);
  }
}
