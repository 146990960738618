import Papa from "papaparse";
import _ from "lodash";
import evaluations from "../data/evaluations.csv";

const MISSION_NAME_FIELD = "mission";
const LATITUDE_FIELD = "latitude";
const LONGITUDE_FIELD = "longitude";
const POD_POSITION_FIELD = "PositionNum";
export const POD_DATE_FIELD = "podDate";
export const POD_ID_FIELD = "id";

export const USER_FIELD = "user";

export async function parseEvaluations(missionName) {
  let start = Date.now();

  let evaluationsFetched = await fetch(evaluations);
  let evaluationsString = await evaluationsFetched.text();

  let parsedData = Papa.parse(evaluationsString, {
    header: true,
    skipEmptyLines: true,
  });

  let rows = parsedData.data;

  let filteredRows = rows.filter(
    (r) => r[MISSION_NAME_FIELD].trim() === missionName.trim()
  );

  let mappedRows = filteredRows.map((row) => ({
    ...row,
    [POD_POSITION_FIELD]: +row[POD_POSITION_FIELD],
    [LATITUDE_FIELD]: parseFloatValue(row[LATITUDE_FIELD]),
    [LONGITUDE_FIELD]: parseFloatValue(row[LONGITUDE_FIELD]),
  }));

  let end = Date.now();

  console.log("Parsing POD CSV file took", end - start);

  return _.groupBy(mappedRows, POD_POSITION_FIELD);
}

function parseFloatValue(v) {
  return +v.replaceAll(",", ".");
}
