import "firebase/firestore";
import { db } from "../config/firebase";
import firebase from "firebase";
import { MISSION_STATUS, FIRESTORE_COLLECTIONS } from "../config/constants";

//Get current authenticated user id
export function getAuthenticatedUserId() {
  return firebase.auth().currentUser.uid;
}

//All pods belonging to a specific user
export function userPodsStream(observer, userId) {
  return db
    .collection(FIRESTORE_COLLECTIONS.PODS)
    .where("fk_user_id", "==", userId)
    .where("isDeleted", "==", 0)
    .onSnapshot(observer);
}

// FUNCTION TO GET A SPECIFIC POD
export function getPod(observer, podId) {
  return db
    .collection(FIRESTORE_COLLECTIONS.PODS)
    .doc(podId)
    .onSnapshot(observer);
}

export async function getPods() {
  let podsRef = db
    .collection(FIRESTORE_COLLECTIONS.PODS)
    .where("isDeleted", "==", 0);

  return podsRef.get();
}

export async function getEvaluatedPods(user_id) {
  let evaluatedPodsRef = db
    .collection(FIRESTORE_COLLECTIONS.PODS)
    .where("fk_user_id", "==", user_id)
    .where("fk_reference_pod", "!=", null);

  let evaluatedPods = await evaluatedPodsRef.get();

  console.log(evaluatedPods, "wtf");

  return evaluatedPods;
}

// FUNCTION TO GET PODS OF A GIVEN MISSION
export async function getMissionPods(missionId) {
  let querySnapshot = await db
    .collection(FIRESTORE_COLLECTIONS.PODS)
    .where("mission_id", "==", missionId)
    .where("isDeleted", "==", 0)
    .get();

  return querySnapshot;
}

// FUNCTION TO UPDATE POD DETAILS
export function updatePod(doc_id, data) {
  return db
    .collection(FIRESTORE_COLLECTIONS.PODS)
    .doc(doc_id)
    .update({
      ...data,
    })
    .then(() => {
      console.log("Pod updated");
    });
}

// FUNCTION TO ADD A POD
export async function addPod(data) {
  let podRef = await db.collection(FIRESTORE_COLLECTIONS.PODS).add(data);
  return podRef.id;
}

// FUNCTION TO DELETE A POD
export function deletePod(doc_id) {
  db.collection(FIRESTORE_COLLECTIONS.PODS)
    .doc(doc_id)
    .delete()
    .then(() => {
      console.log("The pod has been deleted successfully.");
    });
}

//All users
export function usersStream(observer) {
  return db
    .collection(FIRESTORE_COLLECTIONS.USERS)
    .where("role", "!=", "Deleted")
    .onSnapshot(observer);
}

//Get all pods from an expert
export function usersFromExpert(observer, currentUserId) {
  return db
    .collection(FIRESTORE_COLLECTIONS.USERS)
    .where("expert", "==", currentUserId)
    .where("role", "!=", "Deleted")
    .onSnapshot(observer);
}

// get a user from an id
export function getUser(observer, user_id) {
  return db
    .collection(FIRESTORE_COLLECTIONS.USERS)
    .doc(user_id)
    .onSnapshot(observer);
}

export function getUsers() {
  return db.collection(FIRESTORE_COLLECTIONS.USERS).get();
}

// FUNCTION TO UPDATE USER DETAILS
export function updateUser(doc_id, fields) {
  return db
    .collection(FIRESTORE_COLLECTIONS.USERS)
    .doc(doc_id)
    .update(fields)
    .then(() => {
      console.log("User updated");
    });
}

// FUNCTION TO BLACKLIST
export function Blacklist(doc_id) {
  return db
    .collection(FIRESTORE_COLLECTIONS.USERS)
    .doc(doc_id)
    .update({
      blacklist: true,
    })
    .then(() => {
      console.log("User updated");
    });
}

// FUNCTION TO WHITELIST
export function Whitelist(doc_id) {
  return db
    .collection(FIRESTORE_COLLECTIONS.USERS)
    .doc(doc_id)
    .update({
      blacklist: false,
    })
    .then(() => {
      console.log("User updated");
    });
}

// FUNCTION TO DELETE LIST
export function Deletelist(doc_id) {
  return db
    .collection(FIRESTORE_COLLECTIONS.USERS)
    .doc(doc_id)
    .update({
      username: "Deleted",
      email: "Deleted",
      role: "Deleted",
    })
    .then(() => {
      console.log("User deleted");
    });
}

// FUNCTION TO REMOVE EXPERT FROM BEGINNER OR ADVANCED USER IN FIREBASE
export function RemoveExpert(doc_id) {
  return db
    .collection(FIRESTORE_COLLECTIONS.USERS)
    .doc(doc_id)
    .update({
      expert: "NA",
    })
    .then(() => {
      console.log("Beginner or Advances user list updated");
    });
}

// FUNCTION TO ADD EXPERT TO BEGINNER OR ADVANCED USER IN FIREBASE
export function AddExpert(doc_id, expert_id) {
  return db
    .collection(FIRESTORE_COLLECTIONS.USERS)
    .doc(doc_id)
    .update({
      expert: expert_id,
    })
    .then(() => {
      console.log("Begginer or Advanced user list updated");
    });
}

// FUNCTION TO MODIFY ATTRIBUTED USER ARRAY FOR EXPERT IN FIREBASE
export function ModifyAttributed(doc_id, id_array) {
  return db
    .collection("users")
    .doc(doc_id)
    .update({
      attributed_users: id_array,
    })
    .then(() => {
      console.log("Expert user list updated");
    });
}

// FUNCTION TO CREATE A NEW MISSION
export async function saveMission(
  ref,
  missionName,
  volunteerUID,
  dueDate,
  segments,
  creatorUID,
  missionID = null
) {
  let missionToSave = {
    ref: ref,
    fk_user_id: volunteerUID,
    due_date: dueDate,
    segments: segments.map((s) => ({
      id: s.id,
      coordinates: s.coordinates.map(
        ([lat, lng]) => new firebase.firestore.GeoPoint(lat, lng)
      ),
    })),
    segment_ids: segments.map((s) => s.id),
  };

  if (ref) {
    let startDate = Date.now();
    missionToSave = { ...missionToSave, start_date: startDate };
  }

  if (missionName) missionToSave.name = missionName;

  if (!missionID) {
    // Saving a new mission
    missionToSave.fk_creator_id = creatorUID;
    missionToSave.status = MISSION_STATUS.TO_DO;

    let docRef = await db
      .collection(FIRESTORE_COLLECTIONS.MISSIONS)
      .add(missionToSave);
    return docRef.id;
  } else {
    // Updating an existing mission
    await db
      .collection(FIRESTORE_COLLECTIONS.MISSIONS)
      .doc(missionID)
      .update(missionToSave);
  }
}

// FUNCTION TO MODIFY MISSION STATUS
export async function changeMissionStatus(missionID, status) {
  let fields = { status: status };

  if (status === MISSION_STATUS.VALIDATED) fields.validated_date = new Date();

  return db
    .collection(FIRESTORE_COLLECTIONS.MISSIONS)
    .doc(missionID)
    .update(fields);
}

// FUNCTION TO DELETE A MISSION
export async function deleteMission(missionID) {
  return db.collection(FIRESTORE_COLLECTIONS.MISSIONS).doc(missionID).delete();
}

// FUNCTION TO STREAM ALL MISSIONS
export function missionsStream(observer, conditions) {
  let missionsRef = db.collection(FIRESTORE_COLLECTIONS.MISSIONS);

  if (conditions) {
    for (let condition of conditions) {
      missionsRef = missionsRef.where(...condition);
    }
  }

  return missionsRef.onSnapshot(observer);
}

export async function getMissions() {
  let missionsRef = db.collection(FIRESTORE_COLLECTIONS.MISSIONS);

  return missionsRef.get();
}

// FUNCTION TO GET A MISSION
export async function getMission(id) {
  return db.collection(FIRESTORE_COLLECTIONS.MISSIONS).doc(id).get();
}

//Function to validate a POD (change POD status to "validated")
export async function validatePOD(podID) {
  console.log("POD ID " + podID);
  return db
    .collection(FIRESTORE_COLLECTIONS.PODS)
    .doc(podID)
    .update({ status: "validated" });
}

//Function to invalidate a POD (change POD status to "toValidate")
export async function invalidatePOD(podID) {
  console.log("POD ID " + podID);
  return db
    .collection(FIRESTORE_COLLECTIONS.PODS)
    .doc(podID)
    .update({ status: "toValidate" });
}

//Function to get a mission score
export async function getMissionUserScore(missionId, userId) {
  const scoreRef = db
    .collection(FIRESTORE_COLLECTIONS.SCORES)
    .where("fk_user_id", "==", userId)
    .where("mission_id", "==", missionId);
  const missionUserScore = await scoreRef.get();

  if (missionUserScore.docs.length > 0) return missionUserScore.docs[0].data();

  return null;
}

//Function to get a mission score
export async function getUserPods(userId) {
  const podsRef = db
    .collection(FIRESTORE_COLLECTIONS.PODS)
    .where("fk_user_id", "==", userId);
  const userPods = await podsRef.get();
  const docs = userPods.docs;
  let userPodsId = [];
  for (let i = 0; i < docs.length; i++) {
    let userPodId = docs[i].id;
    userPodsId.push(userPodId);
  }
  return userPodsId;
}
