import { useTranslation } from "react-i18next";
import { Check, Remove } from "@material-ui/icons";
import { TableCell, TableRow } from "@material-ui/core";

import _ from "lodash";

export default function CompareToReference({
  field,
  label,
  pod,
  referencePod,
  options,
}) {
  const [t] = useTranslation("common");

  function renderValue(missionPod) {
    if (!missionPod) return "---";

    return !options ? (
      _.isBoolean(missionPod[field]) ? (
        missionPod[field] ? (
          <Check />
        ) : (
          <Remove />
        )
      ) : (
        missionPod[field]
      )
    ) : (
      t(options.find((o) => o.id === missionPod[field]).value, {
        ns: "mobile",
      })
    );
  }

  function selectClass(field) {
    if (!pod) return null;
    if (pod[field] === referencePod[field]) return "correct-row";
    else if (Math.abs(referencePod[field] - pod[field]) == 1)
      return "incorrect-row";
    else return "red-row";
    // let allEqual = mappedPods.every((p) => p[field] === mappedPods[0][field]);
    //
    // if (allEqual) return "correct-row";
    // else return "incorrect-row";
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row" className={selectClass(field)}>
        {t(label, { ns: "mobile" })}
      </TableCell>
      <TableCell className={selectClass(field)}>{renderValue(pod)}</TableCell>
      <TableCell>{renderValue(referencePod)}</TableCell>
      {/*{pods.map((pod) => {*/}
      {/*  let missionPod = missionPods.find((mp) => mp.id === pod.id);*/}

      {/*  return (*/}
      {/*    <TableCell key={missionPod.id}>{renderValue(missionPod)}</TableCell>*/}
      {/*  );*/}
      {/*})}*/}
    </TableRow>
  );
}
