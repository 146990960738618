import React, { useContext, useState } from "react";
import TableBody from "@material-ui/core/TableBody";
import "../App.css";
import { UserContext } from "../context/UserContext";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import PodRowDelete from "./PodDeleteRow";

export default function PodFindUser() {
  const [selectedVolunteer, setSelectedVolunteer] = useState("Test");
  const { users: volunteers } = useContext(UserContext);
  const [findUser, setFindUser] = useState("Test");

  const handleUserChange = (e) => {
    setSelectedVolunteer(e.target.value);

    volunteers.forEach((v) => {
      if (v.user_id === e.target.value) {
        setFindUser(v);
      }
    });

    console.log(findUser);
  };

  return (
    <div>
      <h1>Page gestion de pod en cours de développement</h1>
      <div>
        <InputLabel>Sélectionner un utilisateur</InputLabel>
        <Select
          defaultValue={selectedVolunteer}
          value={selectedVolunteer}
          onChange={handleUserChange}
        >
          <MenuItem key={"first"} value={"Test"}>
            Choisissez un utlisateur
          </MenuItem>
          {volunteers.map((v) => (
            <MenuItem key={v.user_id} value={v.user_id}>
              {v.username} ({v.email})
            </MenuItem>
          ))}
        </Select>
      </div>
      <br />

      {findUser ? (
        <div>
          <TableBody>
            {findUser.user_id ? (
              <PodRowDelete key={findUser.user_id} row={findUser} />
            ) : null}
          </TableBody>
        </div>
      ) : null}
    </div>
  );
}
