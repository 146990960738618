import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function SingleChoiceDimension({
  fieldKey,
  value,
  setValue,
  label,
  options,
}) {
  const [t] = useTranslation();

  return (
    <FormControl fullWidth>
      <InputLabel id={`label-${fieldKey}`}>{label}</InputLabel>
      <Select
        labelId={`label-${fieldKey}`}
        value={value}
        label={label}
        onChange={setValue}
      >
        {options.map((option) => (
          <MenuItem value={option.id} key={option.id}>
            {t(option.value, { ns: "mobile" })}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
