import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";

export default function MultipleChoiceDimension({
  title,
  values,
  onChange,
  options,
}) {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant="h5" style={{ marginTop: "0.5em" }}>
        {title}
      </Typography>
      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            key={option.fieldKey}
            control={
              <Checkbox
                checked={values[option.fieldKey]}
                onChange={() =>
                  onChange(option.fieldKey, !values[option.fieldKey])
                }
              />
            }
            label={t(option.label, { ns: "mobile" })}
          />
        ))}
      </FormGroup>
    </div>
  );
}
