import Contact from "../screens/Contact";
import UserManagement from "../screens/UserManagement";
import Home from "../screens/Home";
import PodValidation from "../screens/PodValidation";
import Mission from "../screens/Mission";
import Missions from "../screens/Missions";
import PodManagement from "../screens/PodManagement";
import CompareMissions from "../screens/CompareMissions";

//ROUTES FOR ADMIN AND EXPERT

const COMMON_LINKS = [
  {
    path: "/",
    sidebarName: "_home",
    component: Home,
  },
  {
    path: "/contact",
    sidebarName: "_contact",
    component: Contact,
  },
];

export const ExpertLinks = [
  ...COMMON_LINKS,
  {
    path: "/pod-validation",
    sidebarName: "_podValidation",
    component: PodValidation,
  },
  {
    path: "/create-mission",
    sidebarName: "_createMission",
    component: Mission,
  },
  {
    path: "/missions",
    sidebarName: "_missionValidation",
    component: Missions,
  },
];

export const AdminLinks = [
  ...ExpertLinks,
  {
    path: "/user-management",
    sidebarName: "_userManagement",
    component: UserManagement,
  },
  {
    path: "/pod-management",
    sidebarName: "_podManagement",
    component: PodManagement,
  },
  {
    path: "/compare-missions",
    sidebarName: "_compareMissions",
    component: CompareMissions,
  },
];
