// set the different attribute to the user, related to firebase document /users
export const mapDocToUser = (document) => {
  return {
    user_id: document.id,
    ...document.data(),
  };
};

// set the different attribute to the user, related to firebase document /pointsofdifficulty
export const mapDocToPod = (document) => {
  return {
    doc_id: document.id,
    ...document.data(),
  };
};
