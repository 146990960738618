import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import firebase, { db } from "../config/firebase";
import { useTranslation } from "react-i18next";
import { SwitchLanguageComponent } from "../utils/NameManagement";

//SIGN IN PAGE
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  avatar: {
    margin: theme.spacing(1),
    color: theme.palette.error.dark,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
    backgroundColor: theme.palette.primary.main,
  },
  root: {
    borderColor: "orange",
  },
}));

export default function SignIn({ history }) {
  const classes = useStyles();

  const [t] = useTranslation("common");

  async function getUserDatabase(id) {
    const request = await db.collection("users").doc(id).get();
    return request.data();
  }

  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;

      try {
        //SIGN IN AND REDIRECTING / DENYING ACCORDING TO THE ROLE / BLACKLIST
        await firebase
          .auth()
          .signInWithEmailAndPassword(email.value, password.value)
          .then((e) => {
            getUserDatabase(e.user.uid);

            getUserDatabase(e.user.uid).then((e) => {
              if (
                e === undefined ||
                e.role === "Beginner" ||
                e.role === "Advanced" ||
                e.blacklist === true
              ) {
                alert(t("_permission"));

                firebase.auth().signOut();
              } else {
                history.push({ pathname: "/" });
              }
            });
          });
      } catch (error) {
        alert(t("_badlyFormatted"));
      }
    },
    [history, t]
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography variant="h4" align={"center"}>
          {t("_title")}
        </Typography>

        <Typography variant="h5">{t("_signIn")}</Typography>
        <form onSubmit={handleLogin} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("_email")}
            name="email"
            autoComplete="email"
            autoFocus
            className={classes.root}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("_password")}
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            {t("common:_signIn")}
          </Button>
        </form>

        <SwitchLanguageComponent />
      </div>
    </Container>
  );
}
