import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import { FormGroup, makeStyles } from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import _ from "lodash";

import { MISSION_STATUS } from "../config/constants";

import { useHistory } from "react-router-dom";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { MissionContext } from "../context/MissionContext";
import SearchField from "../components/SearchField";
import { getComparator } from "../utils/comparator";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mapContainer: {
    height: "500px",
    borderRadius: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rotateIcon: {
    animation: "spin 3s linear infinite",
    animationDirection: "reverse",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
}));

export default function CompareMissions() {
  const [t] = useTranslation("common");

  const history = useHistory();

  const classes = useStyles();

  const {
    missions,
    orderComparison,
    orderByComparison,
    setOrderComparison,
    setOrderByComparison,
    filterText,
  } = useContext(MissionContext);

  const handleRequestSort = (event, property) => {
    const isAsc = orderByComparison === property && orderComparison === "asc";
    setOrderComparison(isAsc ? "desc" : "asc");
    setOrderByComparison(property);
  };

  const groupedMissions =
    missions && _.groupBy(missions, (m) => m.name?.trim());

  const filteredGroupedMissions =
    groupedMissions &&
    Object.entries(groupedMissions)
      .map(([name, missions]) => {
        let missionIDs = missions
          .filter((m) => m.status === MISSION_STATUS.VALIDATED)
          .map((m) => m.mission_id);

        return {
          name: name,
          missionIDs: missionIDs,
          nbUsers: missionIDs.length,
        };
      })
      .filter((missionGroup) => missionGroup.missionIDs.length > 0);

  const compareMission = (mission) => {
    const paramName = "missionID";

    let params = new URLSearchParams();
    for (let missionID of filteredGroupedMissions.find(
      (m) => m.name === mission.name
    ).missionIDs) {
      params.append(paramName, missionID);
    }

    let link = `/mission-comparison?${params.toString()}`;

    history.push(link);
  };

  const filteredMissions = filterText
    ? filteredGroupedMissions.filter((m) => {
        // Keep only fields that can be filtered
        const fields = _.pick(m, ["name"]);

        return Object.values(fields).some((v) =>
          v.toLowerCase().includes(filterText.toLowerCase())
        );
      })
    : filteredGroupedMissions;

  const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("_missionName"),
    },
    {
      id: "nbUsers",
      numeric: false,
      disablePadding: false,
      label: t("_nbUsers"),
    },
  ];

  if (!missions)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <SyncIcon className={classes.rotateIcon} fontSize="large" />
      </div>
    );

  return (
    <div
      className={classes.root}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h2>{t("_compareMissions")}</h2>
      <FormGroup row>
        <div style={{ margin: "0.5em" }}>
          <SearchField />
        </div>
      </FormGroup>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <EnhancedTableHead
            order={orderComparison}
            orderBy={orderByComparison}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />

          <TableBody>
            {filteredMissions
              .sort(getComparator(orderComparison, orderByComparison))
              .map((mission) => {
                return (
                  <TableRow
                    style={{ backgroundColor: "#8ab6d6", cursor: "pointer" }}
                    key={mission.name}
                    onClick={() => compareMission(mission)}
                  >
                    <TableCell>{mission.name || "---"}</TableCell>
                    <TableCell>{mission.nbUsers}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
