import { Button, IconButton, Modal } from "@material-ui/core";
import { List as ListIcon, Close, CloudDownload } from "@material-ui/icons";
import moment from "moment/moment";
import { POD_DATE_FIELD, POD_ID_FIELD, USER_FIELD } from "../utils/evaluations";
import { DATE_TIME_FORMAT_MOMENT_MINUTES } from "../config/constants";
import { useTranslation } from "react-i18next";
import { saveAs } from "file-saver";

export default function PhotoComparisonModal({
  photosModalOpen,
  togglePhotosModal,
  toggleCriteriaModalOpen,
  groupedPODs,
  selectedPosition,
  missionPods,
  users,
}) {
  const [t] = useTranslation("common");

  const saveImage = async (url) => {
    let imageResponse = await fetch(url);
    let imageBlob = await imageResponse.blob();

    saveAs(
      imageBlob,
      url.split("/").pop().split("#")[0].split("?")[0].replace("pods%2F", "")
    );
  };

  return (
    <Modal open={photosModalOpen} onClose={togglePhotosModal}>
      <div
        style={{
          display: "flex",
          height: "100%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "none",
        }}
      >
        <div
          style={{
            width: "95%",
            height: "90%",
            backgroundColor: "white",
            boxShadow: "0px 0px 13px -3px black",
            padding: "1em",
            pointerEvents: "all",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0.5em",
              right: "0.5em",
            }}
          >
            <IconButton onClick={togglePhotosModal}>
              <Close />
            </IconButton>
          </div>
          <Button
            startIcon={<ListIcon />}
            variant="contained"
            color="primary"
            onClick={toggleCriteriaModalOpen}
            style={{ width: "20em" }}
          >
            {t("_compareCriteria")}
          </Button>
          <div className="columns">
            {groupedPODs &&
              selectedPosition &&
              groupedPODs[selectedPosition]
                .sort((p1, p2) => {
                  if (
                    moment(p1[POD_DATE_FIELD]).isBefore(
                      p2[POD_DATE_FIELD],
                      "day"
                    )
                  )
                    return -1;
                  else if (
                    moment(p1[POD_DATE_FIELD]).isAfter(
                      p2[POD_DATE_FIELD],
                      "day"
                    )
                  )
                    return 1;

                  return p1[USER_FIELD].localeCompare(p2[USER_FIELD]);
                })
                .map((pod) => (
                  <div className="column" key={pod.id}>
                    <h4 style={{ textAlign: "center" }}>
                      {
                        users.find(
                          (u) =>
                            u.user_id ===
                            missionPods.find((p) => p.id === pod[POD_ID_FIELD])
                              ?.fk_user_id
                        )?.username
                      }{" "}
                      -{" "}
                      {moment(pod[POD_DATE_FIELD]).format(
                        DATE_TIME_FORMAT_MOMENT_MINUTES
                      )}
                    </h4>
                    <div className="photos">
                      {missionPods
                        .find((p) => p.id === pod[POD_ID_FIELD])
                        ?.img_download_urls.filter((i) => i)
                        .map((i) => (
                          <a
                            href={i}
                            onClick={async (e) => {
                              e.preventDefault();
                              await saveImage(i);
                            }}
                            title={t("_downloadPhoto")}
                            key={i}
                            style={{
                              display: "block",
                              position: "relative",
                            }}
                            className="photo-link"
                          >
                            <CloudDownload
                              style={{
                                width: "48px",
                                height: "48px",
                              }}
                              className="download-photo"
                            />
                            <img
                              className="photo-preview"
                              src={i}
                              alt={pod.id}
                            />
                          </a>
                        ))}
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}
