import { useTranslation } from "react-i18next";
import React, { useContext } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
} from "@material-ui/core";
import SyncIcon from "@material-ui/icons/Sync";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import _ from "lodash";

import { MISSION_STATUS, DATE_FORMAT_MOMENT } from "../config/constants";
import moment from "moment";
import {
  CheckCircleOutline,
  HelpOutline,
  PauseCircleOutline,
  PlayCircleOutline,
} from "@material-ui/icons";
import { blue, green, grey, orange, indigo } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import EnhancedTableHead from "../components/EnhancedTableHead";
import { MissionContext } from "../context/MissionContext";
import SearchField from "../components/SearchField";
import { getComparator } from "../utils/comparator";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mapContainer: {
    height: "500px",
    borderRadius: 0,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  rotateIcon: {
    animation: "spin 3s linear infinite",
    animationDirection: "reverse",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(360deg)",
    },
    "100%": {
      transform: "rotate(0deg)",
    },
  },
}));

export default function Missions() {
  const [t] = useTranslation("common");

  const history = useHistory();

  const classes = useStyles();

  const { users } = useContext(UserContext);

  const {
    missions,
    hideValidated,
    setHideValidated,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    filterText,
  } = useContext(MissionContext);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const missionStatusIcon = (status) => {
    const title = t(`_${status}`);

    switch (status) {
      case MISSION_STATUS.TO_DO:
        return <HelpOutline style={{ color: grey[500] }} titleAccess={title} />;
      case MISSION_STATUS.STARTED:
        return (
          <PlayCircleOutline style={{ color: blue[500] }} titleAccess={title} />
        );
      case MISSION_STATUS.STOPPED:
        return (
          <PauseCircleOutline
            style={{ color: indigo[500] }}
            titleAccess={title}
          />
        );
      case MISSION_STATUS.TO_VALIDATE:
        return (
          <CheckCircleOutline
            style={{ color: orange[500] }}
            titleAccess={title}
          />
        );
      case MISSION_STATUS.VALIDATED:
        return (
          <CheckCircleOutline
            style={{ color: green[500] }}
            titleAccess={title}
          />
        );
      default:
        console.log(`Status ${status} is not supported!`);
    }
  };

  const goToMission = (mission) => {
    let link =
      mission.status === MISSION_STATUS.TO_DO
        ? `/update-mission/${mission.mission_id}`
        : `/validate-mission/${mission.mission_id}`;

    history.push(link);
  };

  const toggleToValidateOnly = () => {
    setHideValidated((o) => !o);
  };

  const formattedMissions =
    missions &&
    missions.map((mission) => {
      let volunteer = users.find((u) => u.user_id === mission.fk_user_id);
      let creator = users.find((u) => u.user_id === mission.fk_creator_id);

      return {
        ...mission,
        volunteer: volunteer && volunteer.username,
        creator: creator && creator.username,
      };
    });

  const filteredMissions = filterText
    ? formattedMissions.filter((m) => {
        // Keep only fields that can be filtered
        const fields = _.pick(m, ["name", "creator", "volunteer"]);

        return Object.values(fields).some((v) =>
          v?.toLowerCase().includes(filterText.toLowerCase())
        );
      })
    : formattedMissions;

  const headCells = [
    {
      id: "due_date",
      numeric: false,
      disablePadding: false,
      label: t("_dueDate"),
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: t("_missionName"),
    },
    {
      id: "volunteer",
      numeric: false,
      disablePadding: false,
      label: t("_username"),
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: t("_status"),
    },
    {
      id: "creator",
      numeric: false,
      disablePadding: false,
      label: t("_creator"),
    },
  ];

  if (!missions)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexGrow: 1,
        }}
      >
        <SyncIcon className={classes.rotateIcon} fontSize="large" />
      </div>
    );

  return (
    <div
      className={classes.root}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h2>{t("_missionValidation")}</h2>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={hideValidated}
              onChange={toggleToValidateOnly}
            />
          }
          label={t("_toValidateOnly")}
        />
        <SearchField />
      </FormGroup>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />

          <TableBody>
            {filteredMissions
              .sort(getComparator(order, orderBy))
              .map((mission) => {
                return (
                  <TableRow
                    style={{ backgroundColor: "#8ab6d6", cursor: "pointer" }}
                    key={mission.mission_id}
                    onClick={() => goToMission(mission)}
                  >
                    <TableCell component="td" scope="row">
                      {moment
                        .unix(mission.due_date.seconds)
                        .format(DATE_FORMAT_MOMENT)}
                    </TableCell>
                    <TableCell>{mission.name || "---"}</TableCell>
                    <TableCell>{mission.volunteer}</TableCell>
                    <TableCell>{missionStatusIcon(mission.status)}</TableCell>
                    <TableCell>{mission.creator}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
