// Page to create a new mission
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { blackIcon } from "../config/icons";
import {
  MapContainer,
  TileLayer,
  ScaleControl,
  Circle,
  Marker,
  Tooltip,
} from "react-leaflet";
import MapDetails from "../components/map/MapDetails";
import Segment from "../components/map/Segment";
import CenterMarker from "../components/map/CenterMarker";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { SWISSTOPO_TOLERANCE } from "../services/segments";
import {
  saveMission,
  getMission,
  deleteMission,
} from "../database/firestore_requests";
import {
  DATE_FORMAT_DATEFNS,
  MAP_TILE_URL,
  POD_MARKERS_MAP,
} from "../config/constants";
import { getPodName } from "../dimensions/dimensionsSingleChoice";
import frLocale from "date-fns/locale/fr-CH";
import deLocale from "date-fns/locale/de";
import itLocale from "date-fns/locale/it";
import enLocale from "date-fns/locale/en-GB";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useHistory, useLocation, useParams } from "react-router-dom";
import haversine from "haversine-distance";
import { UserContext } from "../context/UserContext";
import CenterOnSegments from "../map/CenterOnSegments";
import { red, blue } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import "../../node_modules/leaflet-geosearch/dist/geosearch.css";
//import "leaflet-search/dist/leaflet-search.min.css";

const localeMap = { fr: frLocale, de: deLocale, it: itLocale, en: enLocale };

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mapContainer: {
    height: "500px",
    borderRadius: 0,
  },
}));

export default function Mission() {
  const INITIAL_COORDS = { lat: 46.198526, lng: 7.3824137 };
  const INITIAL_ZOOM = 10;

  const history = useHistory();

  const location = useLocation();

  const params = useParams();

  const { user } = useContext(UserContext);

  const [t, i18n] = useTranslation("common");

  const [center, setCenter] = useState(
    location.state?.center || INITIAL_COORDS
  );
  const [zoom, setZoom] = useState(INITIAL_ZOOM);

  const [missionName, setMissionName] = useState("");

  const [selectedVolunteer, setSelectedVolunteer] = useState("");
  const [dueDate, setDueDate] = useState(null);

  const [segments, setSegments] = useState(null);
  const [fetchingSegments, setFetchingSegments] = useState(false);

  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedOnly, setSelectedOnly] = useState(false);
  const [selectedRef, setSelectedRef] = useState(false);

  const [missionID, setMissionID] = useState(null);
  const [missionPods, setMissionPods] = useState([]);

  const podID = params.podID;

  /* TODO - This will be used again when the segment-blocking feature is enabled */
  //const [unavailableSegments, setUnavailableSegments] = useState([]);

  const { users: volunteers } = useContext(UserContext);

  const isEditing = useMemo(() => {
    return location.pathname.includes("update") && params.id;
  }, [params, location]);

  const classes = useStyles();

  const mapDetailsContainer = useRef();

  useEffect(() => {
    if (location.state) {
      console.log("Setting values from location state!");
      setMissionName(location.state.missionName);
      setDueDate(location.state.dueDate);
      setSelectedSegments(location.state.selectedSegments);
      setMissionID(location.state.missionID);
      setMissionPods(location.state.missionPods);
    }
  }, [location]);

  // TODO - Put this back after the first tests are done
  // Fetch missions to determine which segments are taken
  /*useEffect(() => {
    const unsubscribe = missionsStream({
      next: (querySnapshot) => {
        const unavailableSegments = querySnapshot.docs
          .filter((d) => d.data() !== undefined)
          .map((d) => {
            let segments = d.data().segments;
            return segments.map((s) => s.id);
          });
        setUnavailableSegments(_.flatten(unavailableSegments));
      },
      error: (error) => {
        setUnavailableSegments([]);
      },
    });
    return () => unsubscribe();
  }, []);*/

  // Fetch mission (if editing)
  useEffect(() => {
    async function fetchMission() {
      if (isEditing) {
        let missionDoc = await getMission(params.id);
        let mission = missionDoc.data();
        setSelectedVolunteer(mission.fk_user_id);
        setDueDate(mission.due_date.toDate());
        setSelectedSegments(
          mission.segments.map((s) => ({
            ...s,
            coordinates: s.coordinates.map((c) => [c.latitude, c.longitude]),
          }))
        );
        if (mission.name) setMissionName(mission.name);
      }
    }

    if (volunteers) fetchMission();
  }, [params.id, isEditing, volunteers]);

  const toggleSegment = (segment) =>
    setSelectedSegments((selectedSegments) => {
      let newSegments = [...selectedSegments];

      let toRemoveIndex = newSegments.findIndex((s) => s.id === segment.id);

      if (toRemoveIndex >= 0) {
        newSegments.splice(toRemoveIndex, 1);
      } else {
        newSegments.push(segment);
      }

      return newSegments;
    });

  const toggleSelectedOnly = () => {
    setSelectedOnly((s) => !s);
  };

  const toogleSelectedRef = () => {
    setSelectedRef((s) => !s);
    console.log("toogle : " + selectedRef);
  };

  const segmentsToDisplay = selectedOnly
    ? selectedSegments
    : _.unionBy(segments, selectedSegments, "id");

  const handleUserChange = (e) => {
    setSelectedVolunteer(e.target.value);
  };

  const handleDateChange = (date) => {
    setDueDate(date);
  };

  const handleCreateClick = async () => {
    await saveMission(
      selectedRef,
      missionName,
      selectedVolunteer,
      dueDate,
      selectedSegments,
      user.user_id,
      params.id ? params.id : null
    );

    history.push({
      pathname: `/missions`,
    });
  };

  const handleDeleteClick = async () => {
    await deleteMission(params.id);

    history.push({
      pathname: "/missions",
    });
  };

  const handleCloneClick = async () => {
    history.push({
      pathname: `/create-mission`,
      state: {
        missionName: missionName,
        dueDate: dueDate,
        selectedSegments: selectedSegments,
        fk_creator_id: user.user_id,
      },
    });
  };

  const totalDistance = useMemo(() => {
    const calculateDistance = (segment) => {
      let totalDistance = 0;
      for (let i = 0; i < segment.coordinates.length - 1; i++) {
        totalDistance +=
          haversine(segment.coordinates[i], segment.coordinates[i + 1]) / 1000;
      }

      return totalDistance;
    };

    if (selectedSegments.length === 0) return 0;

    return selectedSegments.reduce((acc, curr) => {
      return acc + calculateDistance(curr);
    }, 0);
  }, [selectedSegments]);

  return (
    <div
      className={classes.root}
      style={{ display: "flex", flexDirection: "column" }}
    >
      <h2>{!isEditing ? t("_createMission") : t("_updateMission")}</h2>
      <div style={{ display: "flex", flexGrow: 1 }}>
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            flex: 1,
          }}
        >
          <MapContainer
            center={[INITIAL_COORDS.lat, INITIAL_COORDS.lng]}
            zoom={INITIAL_ZOOM}
            id="leafletMap"
            className={classes.mapContainer}
            scrollWheelZoom="center"
            doubleClickZoom="center"
            style={{ flexGrow: 1 }}
            whenReady={(map) => {
              // Force invalidation to fix flexbox bug where map center is not correct
              setTimeout(() => {
                map.target.invalidateSize(false);
              }, 0);
            }}
          >
            <TileLayer url={MAP_TILE_URL} />
            <ScaleControl position="topright" imperial={false} maxWidth={200} />
            <MapDetails
              containerRef={mapDetailsContainer}
              center={center}
              setCenter={setCenter}
              zoom={zoom}
              setZoom={setZoom}
              fetchingSegments={fetchingSegments}
              setFetchingSegments={setFetchingSegments}
              segments={segments}
              setSegments={setSegments}
            />
            <CenterMarker fetchingSegments={fetchingSegments} />
            {segmentsToDisplay.map((s) => (
              <Segment
                key={s.id}
                id={s.id}
                selected={selectedSegments.find(
                  (segment) => segment.id === s.id
                )}
                /*unavailable={unavailableSegments.includes(s.id)}*/ // TODO - Put this back after the first tests are done
                coordinates={s.coordinates}
                toggleSegment={toggleSegment}
              />
            ))}
            {/* TODO - Show Circle with search radius? */}
            {fetchingSegments && (
              <Circle center={center} radius={SWISSTOPO_TOLERANCE} />
            )}
            {/* Component to fit bounds of the map when editing/cloning a mission */}
            {missionPods?.length > 0 &&
              missionPods.map((p) => (
                <Marker
                  key={p.id}
                  position={[p.geo_position.latitude, p.geo_position.longitude]}
                  eventHandlers={{
                    click: () => {
                      history.push(`/validate-mission/${params.id}/${p.id}`);
                      history.push({
                        pathname: `/pod/${p.id}`,
                      });
                    },
                  }}
                  icon={POD_MARKERS_MAP[p.status] ?? blackIcon}
                >
                  <Tooltip
                    permanent={p.id === podID}
                    key={`${p.id}-${p.id === podID}`}
                  >
                    {
                      <div>
                        {p.name && (
                          <>
                            <span>
                              <strong>
                                {t(
                                  getPodName().find((o) => o.id === p.name)
                                    ?.value,
                                  {
                                    ns: "mobile",
                                  }
                                )}
                              </strong>
                            </span>
                            <br />
                          </>
                        )}
                        {p.description && (
                          <>
                            <span>{p.description}</span>
                            <br />
                          </>
                        )}
                        <span>
                          {t("_status")} : {t(`_${p.status}`)}
                        </span>
                      </div>
                    }
                  </Tooltip>
                </Marker>
              ))}
            {(location.state || isEditing) && selectedSegments.length > 0 && (
              <CenterOnSegments segments={selectedSegments} />
            )}
          </MapContainer>
          <div ref={mapDetailsContainer} />
        </div>
        <div style={{ flex: 1, padding: "0 0.5em" }}>
          <div>
            <input
              id="selected-only"
              type="checkbox"
              onClick={toggleSelectedOnly}
            />
            <label htmlFor="selected-only">{t("_selectedOnly")}</label>
          </div>

          {user.role === "Administrator" && (
            <div>
              <input
                id="selected-only"
                type="checkbox"
                onClick={toogleSelectedRef}
              />
              <label htmlFor="selected-only">{t("_referenceMission")}</label>
            </div>
          )}

          <h3>{t("_selectedSegments")}</h3>
          {selectedSegments.length === 0 && "---"}
          {selectedSegments.length > 0 && (
            <div>
              <p>
                {t("_nbSegments")} : {selectedSegments.length}
              </p>
              <p>
                {t("_totalDistance")} : {totalDistance.toFixed(2)}km
              </p>
            </div>
          )}
          {selectedSegments.length > 0 && (
            <div>
              <h3>
                {t("_missionName")} ({t("_optional")})
              </h3>
              <TextField
                fullWidth
                placeholder={t("_missionName")}
                value={missionName}
                onChange={(e) => setMissionName(e.target.value)}
              />
              <h3>{t("_assignSegments")}</h3>
              <FormControl fullWidth variant="filled">
                <InputLabel>{t("_selectUser")}</InputLabel>
                <Select value={selectedVolunteer} onChange={handleUserChange}>
                  {volunteers.map((v) => (
                    <MenuItem key={v.user_id} value={v.user_id}>
                      {v.username} ({v.email})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={localeMap[i18n.language]}
              >
                <KeyboardDatePicker
                  disableToolbar
                  autoOk={true}
                  variant="inline"
                  format={DATE_FORMAT_DATEFNS}
                  margin="normal"
                  label={t("_selectDueDate")}
                  value={dueDate}
                  onChange={handleDateChange}
                  style={{ width: "100%" }}
                  invalidDateMessage={t("_invalidDate")}
                />
              </MuiPickersUtilsProvider>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  selectedSegments.length === 0 ||
                  !selectedVolunteer ||
                  !dueDate
                }
                onClick={handleCreateClick}
                startIcon={<SaveIcon />}
              >
                {!isEditing ? t("_createMission") : t("_updateMission")}
              </Button>{" "}
              {isEditing && (
                <>
                  <Button
                    variant="contained"
                    onClick={handleDeleteClick}
                    startIcon={<DeleteIcon />}
                    style={{ color: "white", backgroundColor: red[500] }}
                  >
                    {t("_deleteMission")}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    onClick={handleCloneClick}
                    startIcon={<FileCopyIcon />}
                    style={{ color: "white", backgroundColor: blue[500] }}
                  >
                    {t("_cloneMission")}
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
