import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";

import _ from "lodash";

export default function CenterOnSegments({
  segments,
  setCenter,
  padding = 100,
}) {
  const [done, setDone] = useState(false);

  const [centerInternal, setCenterInternal] = useState(null);

  // Update center to set correctly it when cloning a mission
  useEffect(() => {
    if (centerInternal && setCenter) {
      console.log("Updating center to", centerInternal);
      setCenter(centerInternal);
    }
  }, [centerInternal, setCenter]);

  const map = useMap();

  if (!done) {
    setDone(true);
    let bounds = _.flatten(segments.map((s) => s.coordinates));
    map.fitBounds(bounds, {
      padding: [padding, padding],
    });

    setCenterInternal(map.getCenter());
  }

  return null;
}
