import {
  dangerDimensions,
  getPodName,
  technicalDimensions,
} from "../dimensions/dimensionsSingleChoice";
import {
  equipment,
  helpForHands,
  otherDangerFactors,
  otherDimensions,
  otherTechnicalFactors,
  pathEnvironment,
} from "../dimensions/dimensionsMultipleChoice";
import moment from "moment/moment";
import {
  DATE_FORMAT_MOMENT_EXPORT,
  DATE_TIME_FORMAT_MOMENT_EXPORT,
} from "../config/constants";

export function formatPODs(
  missions,
  pods,
  users,
  t,
  formatVariables,
  formatValues
) {
  let header = [
    "id",
    "pod-date",
    "user",
    "mission-name",
    "mission-due-date",
    "latitude",
    "longitude",
    formatVariables ? t("_technicalLevel") : "technicalLevel",
    formatVariables ? t("_riskLevel") : "riskLevel",
    formatVariables ? t("_podName") : "name",
    ...technicalDimensions.map((d) =>
      formatVariables ? t(d.placeholderKey, { ns: "mobile" }) : d.fieldKey
    ),
    ...otherTechnicalFactors.map((d) =>
      formatVariables ? t(d.label, { ns: "mobile" }) : d.fieldKey
    ),
    ...helpForHands.map((d) =>
      formatVariables ? t(d.label, { ns: "mobile" }) : d.fieldKey
    ),
    ...dangerDimensions.map((d) =>
      formatVariables ? t(d.placeholderKey, { ns: "mobile" }) : d.fieldKey
    ),
    ...pathEnvironment.map((d) =>
      formatVariables ? t(d.label, { ns: "mobile" }) : d.fieldKey
    ),
    ...otherDangerFactors.map((d) =>
      formatVariables ? t(d.label, { ns: "mobile" }) : d.fieldKey
    ),
    ...equipment.map((d) =>
      formatVariables ? t(d.label, { ns: "mobile" }) : d.fieldKey
    ),
    ...otherDimensions.map((d) =>
      formatVariables ? t(d.label, { ns: "mobile" }) : d.fieldKey
    ),
  ];
  let records = [];

  const NA = "N/A";

  const mapMultipleChoiceFields = (pod, choices) => {
    return choices.map((c) =>
      pod[c.fieldKey]
        ? formatValues
          ? t("_yes", { ns: "mobile" })
          : "YES"
        : formatValues
        ? t("_no", { ns: "mobile" })
        : "NO"
    );
  };

  for (let pod of pods) {
    let missionName = missions.find((m) => m.id === pod.mission_id)?.name || NA;

    let record = [
      // ID
      pod.id,
      // Date
      moment(pod.date.toDate()).format(DATE_TIME_FORMAT_MOMENT_EXPORT),
      // User
      users.find((u) => u.id === pod.fk_user_id)?.username || NA,
      // Mission Name
      missionName,
      // Mission Due Date
      moment(pod.date.toDate()).format(DATE_FORMAT_MOMENT_EXPORT),
      // Latitude & Longitude
      pod.geo_position.toJSON().latitude,
      pod.geo_position.toJSON().longitude,
      // Levels
      pod.level_technical,
      pod.level_risk,
      // Name
      pod.name
        ? formatValues
          ? t(getPodName().find((n) => n.id === pod.name).value, {
              ns: "mobile",
            })
          : pod.name
        : NA,
      // Technical Dimensions
      ...technicalDimensions.map((d) =>
        pod[d.fieldKey]
          ? formatValues
            ? t(d.options.find((o) => o.id === pod[d.fieldKey]).value, {
                ns: "mobile",
              })
            : pod[d.fieldKey]
          : NA
      ),
      // Other technical factors
      ...mapMultipleChoiceFields(pod, otherTechnicalFactors),
      ...mapMultipleChoiceFields(pod, helpForHands),
      // Danger Dimensions
      ...dangerDimensions.map((d) =>
        pod[d.fieldKey]
          ? formatValues
            ? t(d.options.find((o) => o.id === pod[d.fieldKey]).value, {
                ns: "mobile",
              })
            : pod[d.fieldKey]
          : NA
      ),
      // Other Danger Dimensions
      ...mapMultipleChoiceFields(pod, pathEnvironment),
      ...mapMultipleChoiceFields(pod, otherDangerFactors),
      ...mapMultipleChoiceFields(pod, equipment),
      // Other Dimensions
      ...mapMultipleChoiceFields(pod, otherDimensions),
    ];

    records.push(record);
  }

  console.log("Header", header);
  console.log("record", records[0]);

  return [header, records];
}
